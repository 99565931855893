import * as React from "react";
import styles from "../progressCounter/progressCounter.module.scss";

export interface IProps {
  progress: number;
  completedQuestions: number;
}

export interface IState {}

class ProgressCounter extends React.Component<IProps, IState> {
  public state = {
    progress: this.props.progress,
    completedQuestions: this.props.completedQuestions
  };

  public counterIncrement = () => {
    this.setState({
      progress: this.state.progress + 1
    });
  };

  public render() {
    return (
      <div className={styles.progressCounter}>
        {this.props.completedQuestions}/{this.props.progress}
      </div>
    );
  }
}

export default ProgressCounter;
