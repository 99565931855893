import * as React from "react";
import { NavLink } from "react-router-dom";
import styles from "./login.module.scss";
import logo from "../../assets/logos/navLogos/northstar-logo.png";
import TextInput from "../textBox/textInput/textInput";
import { authenticateUser, signingOut } from "../../reducer/userReducer";
import { connect } from "react-redux";
import { IStore } from "../../reducer";

export interface IReactProps {}

export interface IReduxProps {
  authenticateUser: (email: string, password: string) => void;
  signingOut: () => void;
}

export interface IState {
  [key: string]: string;
}

class Login extends React.Component<IReactProps & IReduxProps, IState> {
  public state: IState = {
    password: "",
    email: ""
  };

  public render() {
    return (
      <main className={styles.log}>
        <div className={styles.div}>
          <img className={styles.img} src={logo} />
          <h1 className={styles.h1}>MY NORTHSTAR</h1>
          <h2 className={styles.h2}>Explore your purpose, login below</h2>

          <form onSubmit={this.handleSubmit} className={styles.form}>
            <fieldset className={styles.form}>
              <label className={styles.label}>Email:</label>
              <TextInput
                inputName="email"
                inputPlaceholder=""
                inputType="email"
                inputValue={this.state.email}
                inputOnChange={this.handleChange}
              />

              <label className={styles.label}>Password:</label>
              <TextInput
                inputName="password"
                inputPlaceholder=""
                inputType="password"
                inputValue={this.state.password}
                inputOnChange={this.handleChange}
              />
            </fieldset>

            <input className={styles.button} type="submit" value="Login" />
          </form>
          <div className={styles.div}>
            <p>
              New to My Northstar?{" "}
              <NavLink to="/registration-page">Sign-up here</NavLink>
            </p>
          </div>
          <NavLink to="/forgot-password">
            <p> Forgot password? </p>
          </NavLink>
        </div>
      </main>
    );
  }

  public alertFunction = () => {
    alert("Your credentials are invalid!");
  };

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    this.setState({ [name]: event.target.value });
  };

  private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    this.props.authenticateUser(this.state.email, this.state.password);
    event.preventDefault();
  };
}

const mapStateToProps = (state: IStore, props: IReactProps) => {
  return {};
};

const mapDispatchToProps = { authenticateUser, signingOut };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
