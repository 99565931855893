import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import logo from "../../assets/logos/navLogos/northstar-logo.png";
import styles from "./header.module.scss";
// redux
import { connect } from "react-redux";
import { setPageName } from "../../reducer/generalReducer";
import { IStore } from "../../reducer";
import { NavLink } from "react-router-dom";
// import {CSSTransition} from "react-transition-group";
import { authenticateUser, signingOut } from "../../reducer/userReducer";

export interface IOwnProps {
  menuIsClicked: () => void;
  isProfileMenuShown: boolean;
  toggleProfileMenu: (event: React.MouseEvent) => void;
  displayName?: string;
}

export interface IReduxProps {
  displayName?: string;
}

export interface IStateProps {
  setPageName: (pageName: string) => {};
  pageName: string;
  signingOut: () => void;
  displayName?: string;
}

export interface IState {
  navShown: boolean;
  // showProfileMenu: boolean;
}

class Header extends React.Component<IOwnProps & IStateProps, IState> {
  public render() {
    const profileMenuJSX = this.props.isProfileMenuShown ? (
      <div className={styles.profileMenu}>
        <ul>
          <NavLink to="/in-app/profile-page">
            <li>Profile</li>
          </NavLink>
          <NavLink to="/login">
            <li onClick={this.props.signingOut}>Log out</li>
          </NavLink>
        </ul>
      </div>
    ) : null;

    return (
      <div className={styles.header}>
        <img src={logo} alt="Northstar Logo" className={styles.logo} />
        <span className={styles.icon} onClick={this.props.menuIsClicked}>
          <FontAwesomeIcon icon="bars" />
        </span>
        <p className={styles.headerText}>{this.props.pageName}</p>

        <span className={styles.profileIcons}>
          <p className="menuName">{this.props.displayName}</p>
          <span onClick={this.props.toggleProfileMenu}>
            <FontAwesomeIcon className={styles.icon2} icon="user-circle" />
            <FontAwesomeIcon className={styles.icon3} icon="sort-down" />
          </span>

          {profileMenuJSX}
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state: IStore, props: IOwnProps) => {
  return {
    pageName: state.general.pageName,
    displayName: state.user.user.displayName
  };
};

const mapDispatchToProps = { setPageName, signingOut };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
