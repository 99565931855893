// const $ = window.$;
import * as $ from "jquery";

var Tip = function(el) {
    // tip group
    var tipG = el.append('g')
        .attr('class', 'star-tip')
        .style('display', 'none'),
        tipH = 18,
        tipStroke = 1,
        pS = +(tipH / 3).toFixed(),
        tipGutter = 16;

    var d = Math.sqrt(Math.pow(pS, 2) * 2) / 2;
    var transform = 'translate(' + tipStroke + ', ' + ((tipH / 2) - d) + ') rotate(45)';

    // tip pointer stroke
    var pStroke = tipG.append('rect')
        .attrs({
            'class': 'star-tip__rect',
            x: -tipStroke,
            y: -tipStroke,
            width: pS + (tipStroke * 2),
            height: pS + (tipStroke * 2),
            transform: transform
        });

    // tip container
    var tipC = tipG.append('rect')
        .attrs({
            'class': 'star-tip__container',
            x: 0,
            y: 0,
            rx: 4,
            ry: 4,
            height: tipH
        });

    // tip pointer
    var pFill = tipG.append('rect')
        .attrs({
            'class': 'star-tip__rect',
            x: 0,
            y: 0,
            width: pS,
            height: pS,
            transform: transform
        });

    // Tip value
    var tipV = tipG.append('text')
        .text('0')
        .attrs({
            'class': 'star-tip__text',
            x: tipGutter / 2
        });

    function sin(x) {
        return Math.sin(x / 180 * Math.PI);
    }

    function cos(x) {
        return Math.cos(x / 180 * Math.PI);
    }

    return {
        show: function(d, cx, cy) {
            var bg = this.style.fill,
                color = this.style.stroke;
                
            tipV.text(d.value);

            tipC.styles({
                'fill': bg,
                'stroke': color
            });

            $('body').append('<span class="star-tip__text star-tip__text--dummy js-dummy-text">' + d.value + '</span>');
            pFill.style('fill', bg);
            pStroke.style('fill', color);

            tipC.attr('width', $('.js-dummy-text').width() + tipGutter + tipStroke);
            tipV.attr('y', function() {
                return $('.js-dummy-text').height();
            });

            $('.js-dummy-text').remove();

            tipG.attr('transform', function() {
                    return 'translate(' + (cx + 5) + ', ' + (cy - (tipH / 2)) + ')';
                })
                .style('display', 'block')
                .transition()
                .duration(150)
                .attr('transform', function() {
                    return 'translate(' + cx + ', ' + (cy - (tipH / 2)) + ')';
                })
                .style('opacity', 1);
        },

        hide: function() {
            tipG.transition()
                .duration(150)
                .style('opacity', 0)
                .each(function() {
                    tipG.style('display', 'none');
                });
        },

        rotate: function(x, y, a) {
            var x2 = cos(a) * x - sin(a) * y;
            var y2 = sin(a) * x + cos(a) * y;
            return [x2, y2];
        }
    };
};

export default Tip;
