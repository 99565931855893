import * as React from "react";
import Login from "../components/login-page/login";

export interface IProps {}

export interface IState {}

class LoginContainer extends React.Component<IProps, IState> {
  public render() {
    return <Login />;
  }
}

export default LoginContainer;
