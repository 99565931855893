import React from "react";
import allQuestions from "../../assets/data/questions";
import { IQuestion } from "../../assets/data/questions";
import Question from "./question";
import styles from "./questionnaire.module.scss";
import { number, string, element } from "prop-types";
import PrimaryButton from "../primaryButton/primaryButton";
import { firestore } from "../../firebase";
import history from "../../history";
import ProgressBar from "../progressBar/progressBar";
import { connect } from "react-redux";
import { IStore } from "../../reducer";
import { IUserState } from "../../reducer/userReducer";
import PageIntro from "../pageIntro/page-intro";
import ProgressCounter from "../progressCounter/progressCounter";

export interface IOwnProps {}

export interface IStateProps {
  user: any;
}

export interface IState {
  answerArray: IQuestion[];
  numberAnswered: number;
  value: string;
  answer: any;
}

export interface ICategoryResult {
  categoryName: string;
  categoryAverage: number;
}

interface IUser {
  uid: any;
  displayName: string;
}
export interface IResult {
  categoryResults: ICategoryResult[];
  overallResult: number;
  date: Date;
  user: IUser;
}

class QuestionnairePage extends React.Component<
  IOwnProps & IStateProps,
  IState
> {
  public state = {
    answerArray: allQuestions,
    numberAnswered: 0,
    answer: " ",
    value: ""
  };

  public copiedAnswerArray = (allQuestions: IQuestion[]) => {
    const questionsCopy = allQuestions.slice();
    return questionsCopy;
  };

  public addValue = (
    category: string,
    newValue: string,
    event: React.MouseEvent<HTMLInputElement>
  ) => {
    const newIQuestion = { category, value: newValue };
    return newIQuestion;
  };

  public editValue = (value: string, category: string, id: number) => {
    allQuestions[id].value = parseInt(value, 10);
    allQuestions[id].isAnswered = true;
    this.getNumberAnswered();
  };

  public getNumberAnswered = () => {
    let count: number = 0;
    this.state.answerArray.forEach(answer => {
      if (answer.isAnswered) {
        count++;
      }
    });
    this.setState({ numberAnswered: count });
  };

  public everyTest = (testObject: IQuestion) => {
    return testObject.isAnswered;
  };

  public randomizeAnswers = () => {
    allQuestions.map(loopItem => {
      loopItem.value = Math.floor(Math.random() * 11);
      loopItem.isAnswered = true;
      // console.log(loopItem.value);
    });
    this.submitAnswers();
  };

  public submitAnswers = () => {
    if (allQuestions.every(this.everyTest)) {
      const moneyArray: number[] = [];
      const friendsArray: number[] = [];
      const healthWellbeingArray: number[] = [];
      const personalGrowthArray: number[] = [];
      const relationshipArray: number[] = [];
      const familyArray: number[] = [];
      const careerArray: number[] = [];
      const funLeisureArray: number[] = [];
      const physicalEnvironmentArray: number[] = [];

      allQuestions.forEach(element => {
        switch (element.category) {
          case "Money":
            moneyArray.push(element.value);
            break;
          case "Health & Wellbeing":
            healthWellbeingArray.push(element.value);
            break;
          case "Personal Growth":
            personalGrowthArray.push(element.value);
            break;
          case "Physical Environment":
            physicalEnvironmentArray.push(element.value);
            break;
          case "Relationships":
            relationshipArray.push(element.value);
            break;
          case "Career":
            careerArray.push(element.value);
            break;
          case "Friends":
            friendsArray.push(element.value);
            break;
          case "Family":
            familyArray.push(element.value);
            break;
          case "Fun & Leisure":
            funLeisureArray.push(element.value);
            break;
        }
      });

      const moneyAvg = (moneyArray: number[]) =>
        moneyArray.reduce((a, b) => a + b, 0) / moneyArray.length;

      const healthWellbeingAvg = (healthWellbeingArray: number[]) =>
        healthWellbeingArray.reduce((a, b) => a + b, 0) /
        healthWellbeingArray.length;

      const personalGrowthAvg = (personalGrowthArray: number[]) =>
        personalGrowthArray.reduce((a, b) => a + b, 0) /
        personalGrowthArray.length;

      const familyAvg = (familyArray: number[]) =>
        familyArray.reduce((a, b) => a + b, 0) / familyArray.length;

      const friendsAvg = (friendsArray: number[]) =>
        friendsArray.reduce((a, b) => a + b, 0) / friendsArray.length;

      const physicalEnvAvg = (physicalEnvironmentArray: number[]) =>
        physicalEnvironmentArray.reduce((a, b) => a + b, 0) /
        physicalEnvironmentArray.length;

      const relationshipAvg = (relationshipArray: number[]) =>
        relationshipArray.reduce((a, b) => a + b, 0) / relationshipArray.length;

      const careerAvg = (careerArray: number[]) =>
        careerArray.reduce((a, b) => a + b, 0) / careerArray.length;

      const funLeisureAvg = (funLeisureArray: number[]) =>
        funLeisureArray.reduce((a, b) => a + b, 0) / funLeisureArray.length;

      const overallAvg = (): number => {
        return (
          (careerAvg(careerArray) +
            familyAvg(familyArray) +
            friendsAvg(friendsArray) +
            funLeisureAvg(funLeisureArray) +
            healthWellbeingAvg(healthWellbeingArray) +
            moneyAvg(moneyArray) +
            personalGrowthAvg(personalGrowthArray) +
            physicalEnvAvg(physicalEnvironmentArray) +
            relationshipAvg(relationshipArray)) /
          9
        );
      };

      const addDate = new Date();

      const categoryValues = [
        { categoryAverage: careerAvg(careerArray), categoryName: "Career" },
        { categoryAverage: familyAvg(familyArray), categoryName: "Family" },
        { categoryAverage: friendsAvg(friendsArray), categoryName: "Friends" },
        {
          categoryAverage: funLeisureAvg(funLeisureArray),
          categoryName: "Fun & Leisure"
        },
        {
          categoryAverage: healthWellbeingAvg(healthWellbeingArray),
          categoryName: "Health & Well Being"
        },
        { categoryAverage: moneyAvg(moneyArray), categoryName: "Money" },
        {
          categoryAverage: personalGrowthAvg(personalGrowthArray),
          categoryName: "Personal Growth"
        },
        {
          categoryAverage: physicalEnvAvg(physicalEnvironmentArray),
          categoryName: "Physical Environment"
        },
        {
          categoryAverage: relationshipAvg(relationshipArray),
          categoryName: "Relationships"
        }
      ];
      const user: IUser = {
        uid: this.props.user.uid,
        displayName: this.props.user.displayName
      };
      const results: IResult = {
        categoryResults: categoryValues,
        date: addDate,
        overallResult: overallAvg(),
        user
      };

      allQuestions.map(question => {
        question.isAnswered = false;
      });

      // Add a new document in collection "questionnaires"
      firestore
        .collection("questionnaires")
        // leave parentheses in .doc() empty and firestore will autoassign a unique document title
        .doc()
        .set({
          categoryResults: categoryValues,
          date: addDate,
          overallResult: overallAvg(),
          user
        })
        .then(() => {
          history.push("/in-app/my-star");
        })
        .catch(() => {});
    }
  };

  public render() {
    return (
      <React.Fragment>
        <ProgressBar
          totalLength={allQuestions.length}
          progress={this.state.numberAnswered}
        />
        <section className={styles.containerMainPage}>
          <div className={styles.questionContainer}>
            {allQuestions.map((loopItem, index) => (
              <Question
                editValue={this.editValue}
                text={loopItem.question}
                category={loopItem.category}
                id={index}
                key={index}
              />
            ))}
          </div>
          <div className={styles.rightPanel}>
            <div className={styles.mobileHidden}>
              <PageIntro heading="">
                Try to answer the questions quickly, don’t ponder too long on
                them. The idea is that you go with your gut and how you feel.
                There is no right answer- honesty is the key to getting the best
                results!
              </PageIntro>
              <ProgressCounter
                progress={allQuestions.length}
                completedQuestions={this.state.numberAnswered}
              />
            </div>
            <div className={styles.buttonContainer}>
              <PrimaryButton
                buttonText="Randomize"
                onClick={this.randomizeAnswers}
              />
              <PrimaryButton buttonText="Submit" onClick={this.submitAnswers} />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IStore, props: IOwnProps) => {
  return {
    ...props,
    user: state.user.user
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnairePage);
