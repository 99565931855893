import * as React from "react";
import styles from "./not-found.module.scss";
import { NavLink } from "react-router-dom";
import { setPageName } from "../../utilities";

export interface IProps {}

export interface IState {}

class NotFound extends React.Component<IProps, IState> {
  public componentDidMount() {
    setPageName("Not Found");
  }

  public render() {
    return (
      <div className={styles.pageNotFoundContainer}>
        <h1>Sorry, page not found</h1>
        <h2>
          Please navigate back to the{" "}
          <NavLink to="/in-app/profile-page">
            <span>Profile Page</span>
          </NavLink>
        </h2>
      </div>
    );
  }
}

export default NotFound;
