import * as React from "react";
import QuestionnairePage from "../components/questionnairePage/questionnairePage";
import { setPageName } from "../utilities";

export interface IProps {}

export interface IState {}

class QuestionniareContainer extends React.Component<IProps, IState> {
  public componentDidMount() {
    setPageName("Questionnaire");
  }

  public render() {
    return (
      <React.Fragment>
        <QuestionnairePage />
      </React.Fragment>
    );
  }
}
export default QuestionniareContainer;
