import * as React from "react";
import styles from "./primaryButton.module.scss";

export interface IProps {
  buttonText: string;
  onClick?: () => void;
  value?: string;
  type?: string;
}

export interface IState {}

class PrimaryButton extends React.Component<IProps, IState> {
  public render() {
    return (
      <button onClick={this.props.onClick} className={styles.button}>
        {this.props.buttonText}
      </button>
    );
  }
}

export default PrimaryButton;
