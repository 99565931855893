import { select } from 'd3-selection';
import * as $ from "jquery";

// const $ = window.$;
var QuickActions = (function() {
	var hasher = require('hasher');
	require('../lib/velocity');

	var activeStar = '';

	var $wrapper = $('.js-quick-actions');
	var $items = $('.js-qa-item');
	var $link = $('.js-qa-link');
	var timeout;
	var orientation = '';

	/**
	 * Transition in
	 */
	function show() {
		$wrapper.show();
		$items.css('opacity', 0).show();
		$items.velocity('finish').velocity('transition.bounceIn', {
			stagger: 100,
			duration: 250
		});
	}

	/**
	 * Transition out
	 */
	function hide() {
		$items.velocity('finish').velocity('transition.bounceOut', {
			stagger: 100,
			duration: 250,
			complete: function() {
				$wrapper.hide().removeClass(orientation);
				orientation = '';
			}
		});

		activeStar = '';
	}

	function slowHide() {
		clearTimeout(timeout);
		timeout = setTimeout(function() {
			hide();
		}, 2000);
	}

	hasher.changed.add(function() {
		$wrapper.fadeOut(250);
		activeStar = '';
	});

	$wrapper.on('mouseover', function() {
		clearTimeout(timeout);
	}).on('mouseleave', slowHide);

	return {
		init: function(name, rect, parentRect) {
			clearTimeout(timeout);

			if (activeStar === name) {
				return;
			} else {
				$wrapper.removeClass(orientation);
				orientation = '';

				activeStar = name;

				$link.each(function(index, el) {
					var ac = $(el).data('action');

					$(el).attr('href', '#' + name + '/' + ac);
				})
				.on('mouseover', function() {
					select(this).select('svg').attr('filter', 'url(#path-glow)');
				})
				.on('mouseleave', function() {
					select(this).select('svg').attr('filter', null);
				});

				var left;

				/*=========================================
				=            Check orientation            =
				=========================================*/
				
				if (
					rect.left + (rect.width / 2) < parentRect.left + (parentRect.width / 2)
				) {
					// show qa on left side of the star
					orientation = 'qa--left';

					left = rect.left + 'px';
				} else {
					orientation = '';
					left = rect.right + 'px';
				}
				
				/*=====  End of Check orientation  ======*/
				

				$wrapper.css({
					'left': left,
					'top': rect.top + (rect.height / 2) + 'px'
				}).addClass(orientation);

				show();
			}
		},
		hide: hide,
		slowHide: slowHide
	};
})();

export default QuickActions;