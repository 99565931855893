import * as React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/logos/navLogos/northstar-logo.png";
import styles from "./forgot.module.scss";
import PrimaryButton from "../primaryButton/primaryButton";
import TextInput from "../textBox/textInput/textInput";
import { auth } from "../../firebase";

export interface IProps {}

export interface IState {
  forgotPassword: string;
}

class Forgot extends React.Component<IProps, IState> {
  public state: IState = {
    forgotPassword: ""
  };
  public render() {
    return (
      <main className={styles.log}>
        <div className={styles.div}>
          <img className={styles.img} src={logo} />
          <h1 className={styles.h1}>MY NORTHSTAR</h1>
          <h2 className={styles.h2}>
            Enter your email to send a password reset link
          </h2>
          <form onSubmit={this.sendResetPasswordTemplate}>
            <fieldset className={styles.form}>
              <label className={styles.label}>Email:</label>
              <TextInput
                inputName={"email"}
                inputPlaceholder={""}
                inputType={"text"}
                inputValue={this.state.forgotPassword}
                inputOnChange={this.updateForgotPasswordState}
              />
            </fieldset>

            <div className={styles.div}>
              {/* <NavLink to="/login"> */}
              <input className={styles.button} type="submit" value="Send" />
              {/* <PrimaryButton buttonText="Send" onClick={this.sendResetPasswordTemplate} /> */}
              {/* </NavLink> */}
            </div>
          </form>
          <NavLink to="/login">
            <p> Back to login </p>
          </NavLink>
        </div>
      </main>
    );
  }

  public updateForgotPasswordState = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      forgotPassword: event.target.value
    });
  };

  public sendResetPasswordTemplate = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    auth.sendPasswordResetEmail(this.state.forgotPassword);

    alert(
      "If your email address is already registered with us, you will receive a reset link by email. Please check your inbox."
    );
  };
}

export default Forgot;
