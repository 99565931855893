import { scaleLinear } from 'd3-scale';

const list = ['#fe006d', '#7e00ff', '#00ffd2'];
class Colors {
	constructor(max = 10, {invert = false, clamp = true} = {}) {
		if (max === 0) max = 10;
		const domain = [0, max / 2, max];

		if (invert) {
			domain.reverse();
		}

		this.scale = scaleLinear()
			.domain(domain)
			.range(list)
			.clamp(clamp);

		this.scale.list = list;

		return this.scale;
	}
}

export default Colors;
