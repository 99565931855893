import * as React from "react";
import { NavLink } from "react-router-dom";
// import styles from "../../../src/components/create-password/password.module.scss";
import TextInput from "../textBox/textInput/textInput";
import logo from "../../assets/logos/navLogos/northstar-logo.png";
import styles from "./password.module.scss";
import PrimaryButton from "../primaryButton/primaryButton";

export interface IProps {}

export interface IState {
  createPassword: string;
  confirmPassword: string;
}

class Password extends React.Component<IProps, IState> {
  public state: IState = {
    createPassword: "",
    confirmPassword: ""
  };
  public render() {
    return (
      <main className={styles.log}>
        <div className={styles.div}>
          <img className={styles.img} src={logo} />
          <h1 className={styles.h1}>MY NORTHSTAR</h1>
          <h2 className={styles.h2}></h2>
          <form>
            <fieldset className={styles.form}>
              <label className={styles.label}>Enter new password:</label>
              <TextInput
                inputName="password"
                inputPlaceholder=""
                inputType="password"
                inputValue={this.state.createPassword}
                inputOnChange={this.updatePasswordState}
              />


              <label className={styles.label}> Confirm new password:</label>
              <TextInput
                inputName="password"
                inputPlaceholder=""
                inputType="password"
                inputValue={this.state.confirmPassword}
                inputOnChange={this.confirmPasswordState}
              />
              <div className={styles.div}>
                <NavLink to="/in-app/profile-page">
                  <PrimaryButton buttonText="Change password" />
                </NavLink>
              </div>
            </fieldset>

          </form>
          <NavLink to="/login">
            <p> Back to login </p>
          </NavLink>
        </div>
      </main>
    );
  }

  public updatePasswordState = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      createPassword: event.target.value
    });
    // console.log(this.state.password);
  };
  public confirmPasswordState = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      confirmPassword: event.target.value
    });
    // console.log(this.state.password);
  };
}

export default Password;
