import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import { IStore } from "../../reducer";
import { authenticateUser } from "../../reducer/userReducer";
import { connect } from "react-redux";

export interface IReactProps {
  [key: string]: any;
}

export interface IReduxProps {
  user: object | null;
}

export interface IComponentState {}

export interface IState {}

class PrivateRoute extends React.Component<
  IReactProps & IReduxProps,
  IComponentState
> {
  public shouldComponentUpdate(nextProps: IReactProps & IReduxProps) {
    // console.log(nextProps);
    return true;
  }

  public render() {
    const { component, ...rest } = this.props;
    return <Route {...rest} render={this.renderRoute} />;
  }

  private renderRoute = () => {
    // console.log(this.props.user);
    if (this.props.user) {
      return <this.props.component />;
    }
    return <Redirect to="/login" />;
  };
}

const mapStateToProps = (state: IStore, props: IReactProps) => {
  return {
    user: state.user.user
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
