export const SET_REGISTRATION_DETAILS = "SET_REGISTRATION_DETAILS";

export interface IRegistrationDetails {
  type: typeof SET_REGISTRATION_DETAILS;
  name: string;
  password: string;
  email: string;
  confirmPassword: string;
}

export interface IRegistrationState {
  name: string;
  password: string;
  email: string;
  confirmPassword: string;
}

export const setLoginDetails = (
  name: string,
  password: string,
  email: string,
  confirmPassword: string
): IRegistrationDetails => {
  return {
    type: SET_REGISTRATION_DETAILS,
    name,
    password,
    email,
    confirmPassword
  };
};

export const registrationState: IRegistrationState = {
  name: "",
  email: "",
  password: "",
  confirmPassword: ""
};

const registrationReducer = (
  state = registrationState,
  action: IRegistrationDetails
) => {
  switch (action.type) {
    case SET_REGISTRATION_DETAILS:
      return {
        ...state,
        name: action.name,
        email: action.email,
        password: action.password,
        confirmPassword: action.confirmPassword
      };
    default:
      return state;
  }
};

export default registrationReducer;
