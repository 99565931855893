import ko from 'knockout';
import template from 'lodash/template';
import tipTemplate from './tooltip/tooltip.html';
import tooltipster from "../../lib/jquery.tooltipster";
import * as $ from "jquery";

// const $ = window.$;
class Tooltip {
	constructor(el, data) {
		this.el = el;
		this.tipContent = ko.observable('');
		this.tipTemplate = data.template;// || tipTemplate;

		this.tipContent(this.createContent(data));

		$(this.el).tooltipster(
			$.extend({
				content: $(this.tipContent()),
			}, data.options ? data.options : {})
		);

		ko.computed(() => {
			if (this.el && $(this.el).length) {
				$(this.el).tooltipster('content', $(this.tipContent()));
			}
		});
	}

	createContent(data) {
		var response = '';

		if (!!data.sections && data.sections.length) {
			this.sections = data.sections;

			var betaContent = '';

			this.sections.forEach(el => {
				betaContent += this.getTemplate(el.title, el.content);
			});

			response = betaContent;

		} else {
			this.title = data.title;
			this.content = data.content;

			response = this.getTemplate(this.title, this.content);
		}

		return response;
	}

	getTemplate(title, content) {
		return template(this.tipTemplate)({
			title: typeof title === 'function' ? title() : title,
			content: typeof content === 'function' ? content() : content,
		});
	}

	update(data) {
		this.tipContent(this.createContent(data));
	}
}

export default Tooltip;
