import * as React from "react";
import styles from "./sign-up-submit-button.module.scss";
import Login from "../login-page/login";
import { NavLink } from "react-router-dom";

export interface IProps {
  buttonText: string;
  confirmLoginDetails: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

export interface IState {}

class SignUpButton extends React.Component<IProps, IState> {
  public render() {
    return (
      // <NavLink to="/registration-page">
      <button
        className={styles.button}
        onClick={this.props.confirmLoginDetails}
      >
        {this.props.buttonText}
      </button>
      // </NavLink>
    );
  }
}

export default SignUpButton;
