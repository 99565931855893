import * as React from "react";
import styles from "./textInput.module.scss";

export interface IProps {
  inputName: string;
  inputPlaceholder: string;
  inputType: string;
  inputValue: string;
  inputOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface IState {}

class TextInput extends React.Component<IProps, IState> {
  // state = { :  }
  public render() {
    return (
      <input
        className={styles.input}
        name={this.props.inputName}
        placeholder={this.props.inputPlaceholder}
        type={this.props.inputType}
        onChange={this.props.inputOnChange}
        value={this.props.inputValue}
        required
      />
    );
  }
}

export default TextInput;
