import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styles from "./inner-app.module.scss";
import Nav from "../components/nav/nav";
import ProfilePage from "../components/profilePage/profile";
import Header from "../components/header/header";
import NotFound from "../components/not-found/not-found";
import QuestionnaireContainer from "./questionnaire-container";
import StarPageContainer from "./star-page-container";

export interface IProps {}

export interface IState {
  navShown: boolean;
  menuShown: boolean;
  isProfileMenuShown: boolean;
}

class InnerPages extends React.Component<IProps, IState> {
  public state = {
    navShown: true,
    menuShown: false,
    isProfileMenuShown: false
  };

  public isNavShown = () => {
    this.setState({ navShown: !this.state.navShown });
  };

  public toggleProfileMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState({ isProfileMenuShown: !this.state.isProfileMenuShown });
  };

  public render() {
    return (
      <React.Fragment>
        <div onClick={() => this.setState({ isProfileMenuShown: false })}>
          <Header
            menuIsClicked={this.isNavShown}
            toggleProfileMenu={this.toggleProfileMenu}
            isProfileMenuShown={this.state.isProfileMenuShown}
          />
          <Nav navShown={this.state.navShown} menuIsClicked={this.isNavShown} />
          <main className={styles.main}>
            <Switch>
              <Route path="/in-app/profile-page" component={ProfilePage} />
              <Route path="/in-app/my-star" component={StarPageContainer} />
              <Route path="/in-app/compare-star" component={NotFound} />
              <Route path="/in-app/not-found" component={NotFound} />
              <Route
                path="/in-app/questionnaire"
                component={QuestionnaireContainer}
                activeClassName="selected"
              />
              <Redirect to="/in-app/my-star" />
            </Switch>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

export default InnerPages;
