// const $ = window.$;
import * as $ from "jquery";

var QA = (function() {
	var ko = require('knockout');
	require('../../../lib/velocity');
	var template = require('./quick-actions.html');
	var timeout;

	var qaList = ko.observableArray([]);
	var qaOrientation = ko.observable('');

	$('.js-qa-wrapper').remove();
	$('body').append('<div class="js-qa-wrapper">'+ template +'</>');
	function ViewModel() {
		this.qa = qaList;
		this.qaOrientation = qaOrientation;

		this.hide = hide;
		this.freez = function() {
			clearTimeout(timeout);
		};
		this.destroy = destroy;
		this.handleClick = handleClick;

		this.itemIn = function(i) {
			$(this).css('opacity', 0);
			$(this).velocity('finish').velocity('transition.bounceIn', {
				duration: 250,
				delay: 50 * i()
			});
		};
	}

	ko.applyBindings(new ViewModel(), $('.js-qa-wrapper')[0]);

	function hide() {
		clearTimeout(timeout);

		timeout = setTimeout(function() {
			$('.js-qa-wrapper .qa__item').velocity('finish').velocity('transition.bounceOut', {
				duration: 250,
				stagger: 50,
				complete: function() {
					qaList([]);
				}
			});
		}, 2000);
	}

	var scrollTimer;
	function scrollHandler() {
		clearTimeout(scrollTimer);

		scrollTimer = setTimeout(function() {
			destroy();
		}, 250);
	}
	document.addEventListener('scroll', scrollHandler);

	function destroy() {
		clearTimeout(timeout);
		qaList([]);
		return true;
	}

	function handleClick(d) {
		d.action();

		return true;
	}

	return {
		init: function(list, d) {
			qaOrientation(d);

			list.map(function(item) {
				var rect = document.getElementById(item.id).getBoundingClientRect();
				item.top = rect.top;
				item.left = rect.left;

				return item;
			});

			qaList(list);

			clearTimeout(timeout);
		},
		hide: hide,
		destroy: destroy
	};
})();

export default QA;