import * as React from "react";
import styles from "./progressBar.module.scss";

export interface IProps {
  totalLength: number;
  progress: number;
}
export interface IState {
  progress: number;
  totalLength: number;
}

class ProgressBar extends React.Component<IProps, IState> {
  public state = {
    totalLength: this.props.totalLength,
    progress: this.props.progress
  };

  public render() {
    const percentProgress =
      (this.props.progress / this.props.totalLength) * 100;

    const barWidth = {
      width: percentProgress + "%"
    };

    const activeOpacity =
      percentProgress >= 1 ? { opacity: 1 } : { opacity: 0.3 };

    return (
      <div className={styles.progressBarAll}>
        <div className={styles.progressBarContainer}>
          <div className={styles.progressOverlayBar} style={barWidth} />
          <div className={styles.progressBaseBar} style={activeOpacity} />
        </div>
      </div>
    );
  }

  public incrementProgress = () => {
    this.setState({
      progress: this.state.progress + 1
    });
  };
}

export default ProgressBar;
