// import hasher from 'hasher';
import starTip from './spare/starTip';
import { min as d3Min, max as d3Max, range } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import { 
	arc as d3Arc, 
	pie as d3Pie, 
	symbol as d3Symbol,
	radialLine,
	curveLinearClosed,
	curveCardinalClosed,
} from 'd3-shape';
import { interpolateString } from 'd3-interpolate';
import { getTransformation } from './utils';
import { select } from 'd3-selection';
import { color } from 'd3-color';
import 'd3-selection-multi';

function StarChart(svg, targetSelector, data, options, index, focus) {
	var resp = {},
		_this = this;

	data.map(function(el) {
		el.value = +el.value;
		return el;
	});

	/*=======================================================
	=            Define deps, config and helpers            =
	=======================================================*/

	var initSize = _this.helpers.getNewSize(_this.cGuide);
	var starPadding = getStarPadding(initSize);
	var defaultSize = _this.helpers.getNewSize(_this.cGuide, starPadding);

	// #cfg
	_this.cfg = {
		svgTarget: targetSelector,
		index: index,

		size: defaultSize,
		maxSize: defaultSize,
		w: defaultSize,
		h: defaultSize,
		parentScale: 1,

		dotRadius: 9,
		levels: 2,
		factor: 1,
		radians: 2 * Math.PI,
		strokeWidth: 4,
		allAxis: 0,
		total: 0,
		angleSlice: 0,
		name: 'core',
		parentName: '',

		stagger: 70,
		starPadding: starPadding,

		init: true,
		data: data,

		isResizing: false,
		isPreview: false,
		mainColor: '#1a3356',
		state: 'hidden'
	};

	// use passed configs
	var availableOptions = ['name', 'transitionDuration', 'stagger', 'size', 'parentName', 'humanName', 'tooltipContent'];
	if (typeof options !== 'undefined') {
		availableOptions.forEach(function(el) {
			if (typeof options[el] !== 'undefined') {
				_this.cfg[el] = options[el];
			}
		});
	}

	// match star size with passed value
	if (options.size) {
		_this.cfg.w = _this.cfg.size;
		_this.cfg.h = _this.cfg.size;
	}

	if (_this.cfg.maxSize > _this.cfg.size) {
		_this.cfg.maxSize = _this.cfg.size;
	} else if (_this.cfg.maxSize <= _this.cfg.size) {
		_this.cfg.w = _this.cfg.maxSize;
		_this.cfg.h = _this.cfg.maxSize;
	}

	breakPointStyles();

	/*=====  End of Define deps, config and helpers  ======*/



	/*===========================================
	=            Define star methods            =
	===========================================*/

	function drawGradient() {
		radialGradient = _this.defs.select('.gradients').append('radialGradient')
			.attr('xlink:href', '#star-gradient')
			.attr('id', _this.cfg.name + '-star-gradient');
	}

	function drawPie(data) {
		if (_this.cfg.init) {
			pie = d3Pie()
				.value(() => 1)
				.padAngle(0.01)
				.sort(null);
		}

		arc = d3Arc()
			.innerRadius(innerRadius)
			.outerRadius(outerRadius);
		labelArc = d3Arc()
			.innerRadius(_this.helpers.centerRadius.call(null, innerRadius, outerRadius))
			.outerRadius(_this.helpers.centerRadius.call(null, innerRadius, outerRadius));

		arcs = $starSlices.selectAll('g')
			.data(pie(data));

		arcs.exit().remove();
			
		var arcG = arcs.enter().append('g')
			.attr('class', 'star__slice')
			.attr('id', d => {
				let axis = d.data.axis.toLowerCase();
				return _this.cfg.name + '-' + axis + '-label';
			})
			.call(appendStuff)
			.merge(arcs)
			// add labels
			.call(selection => {
				selection.select('textPath')
					.attr('startOffset', _this.helpers.checkOrientation)
					.attr('xlink:href', (d, i) => `#slice-path-${i}-${_this.cfg.name}`)
					.text(d => {
						return d.data.label.toUpperCase();
					});
			})
			.style('color', d => _this.getColor(d.data[_this.keyName]))
			.attr('transform', 'translate(' + outerRadius + ', ' + outerRadius + ')');


		// Set star pointer size and position
		arcG.select('.star__triangle')
			.attr('d', d3Symbol()
				.type({
					draw: function(context, size) {
						let sqrt3 = Math.sqrt(3);
						let y = Math.sqrt(size / (sqrt3 * 3));

						context.moveTo(0, y * 2);
						context.lineTo(-sqrt3 * y, -y);
						context.lineTo(sqrt3 * y, -y);
						context.closePath();
					}
				})
				.size(() => _this.cfg.triangleSize)
			)
			.attr('transform', function(d) {
				var radius = _this.helpers.bottomRadius(Math.sqrt(_this.cfg.triangleSize), innerRadius, outerRadius, _this.cfg.sliceHeight / 1.25);
				symbolArc.innerRadius(radius + 3);
				symbolArc.outerRadius(radius + 3);

				if (_this.cfg.total === 1) {
					return 'translate(' + symbolArc.centroid({startAngle: 0, endAngle: 0}) + ') rotate(' + 0 + ')';
				} else {
					return 'translate(' + symbolArc.centroid(d) + ') rotate(' + _this.helpers.rotateAngle(d) + ')';
				}
			});

		arcG.select('.deff-arc')
			.attr('d', labelArc);

		arcG.select('.star__arc')
			.attr('d', arc);

		// style label text
		arcG.select('text')
			.attr('font-size', _this.cfg.labelFz)
			.style('pointer-events', 'none');
	}

	// Append path and text label to pie slice
	function appendStuff(selection) {
		// Append star pointer
		selection
			.append('path')
			.attr('class', 'star__triangle');


		// Append slice
		selection
			.append('path')
			.attr('class', 'star__arc')
			.call(selection => {
				if (_this.cfg.isPreview) {
					selection.style('fill', _this.cfg.starColor);
				}
			})
			.on('mouseover', function(d) {
				let axis = d.data.axis.toLowerCase(),
					targetName = _this.helpers.getTarget(_this.cfg.guides, axis);

				if (targetName) {
					let el = select('#' + targetName + '-star');
					if (el.classed('is-preview')) {
						el.classed('is-focused', true);
						el.select('.star__dummy').attr('filter', 'url(#star-guide-glow)');
					}
				}

				if (d.data.qa) {
					_this.sliceQa.init(d.data.qa, _this.helpers.getQaOrientation(d));
				}

				if (typeof _this.onSliceMouseover === 'function') {
					_this.onSliceMouseover(d);
				}
			})
			.on('mouseleave', function(d) {
				let axis = d.data.axis.toLowerCase(),
					targetName = _this.helpers.getTarget(_this.cfg.guides, axis);

				if (targetName) {
					let el = select('#' + targetName + '-star');
					if (el.classed('is-preview')) {
						el.classed('is-focused', false);
					}
				}

				if (d.data.qa) {
					_this.sliceQa.hide([]);
				}


				if (typeof _this.onSliceMouseleave === 'function') {
					_this.onSliceMouseleave(d);
				}
			})
			.on('click', function(d) {
				if (typeof _this.onSliceClick === 'function') {
					_this.onSliceClick(d);
				}
			});

		/*=======================================
		=            Draw label text            =
		=======================================*/

		selection.append('path')
			.attr('class', 'deff-arc')
			.attr('fill', 'none')
			.attr('stroke', 'none')
			.attr('id', (d, i) => `slice-path-${i}-${_this.cfg.name}`);

		selection.append('text')
			.attr('dy', 5)
			.attr('text-rendering', 'geometricPrecision')
			.attr('class', 'star__label')
			.call(selection => {
				if (_this.cfg.isPreview) {
					selection.style('opacity', 0);
				}
			})
			.append('textPath')
				.attr('text-anchor', 'middle');

		/*=====  End of Draw label text  ======*/
	}

	function addAxes(data) {
		_this.cfg.scaleVal = rScale(_this.maxValue) + (_this.cfg.sliceHeight / 1.25);

		if (!_this.defs.select('#axes-count-' + data.length).node()) {
			_this.drawAxes(range(data.length));
		} else if (!_this.cfg.init) {
			_this.drawAxes(range(data.length));
		}

		var el = $dataWrapper.select('.star-axes-link');
		if (!el.node()) {
			el = $dataWrapper.insert('use', ':first-child').attr('class', 'star-axes-link');
		}
		el.attr('xlink:href', '#axes-count-' + data.length);
	}

	function addFakePoints(data) {
		var sD = [];
		var divide = +(((360 / d3Min([_this.cfg.total, 6]) * 100) / 3600) / 1.2).toFixed(1);

		if (divide < 2) {
			divide = 1;
		}

		var scale = scaleLinear().range([70, 10]).domain([0, Math.ceil((_this.cfg.total / 10)) * 10]);

		data.forEach(function(d, i) {
			var i2 = (i === _this.cfg.total - 1) ? 0 : i + 1,
				a = data[i][_this.keyName],
				b = data[i2][_this.keyName],
				min = d3Min([a, b]),
				midY;


			if (min < ((20 / 100) * _this.maxValue)) {
				midY = Math.floor(Math.sqrt((a + b)));
			} else {
				var substract = (scale(_this.cfg.total) / 100) * min;
				midY = min - substract;
			}


			sD.push(a);
			sD.push(midY);
		});

		return sD;
	}

	function drawArea(data) {
		var angle = Math.PI * 2 / (_this.cfg.total * 2);
		var radarLine = radialLine()
			.curve(curveCardinalClosed)
			.radius(function(d) {
				return rScale(d);
			})
			.angle(function(d, i) {
				return i * angle;
			});

		var fakeData = addFakePoints(data),
			area;

		if (_this.cfg.init) {
			area = $pointsWrapper.append('path')
				.datum(fakeData)
				.attr('d', function(d) {
					return radarLine(d);
				})
				.attr('class', 'star-area')
				.style('stroke-width', _this.cfg.strokeWidth)
				.style('stroke', 'url(#' + _this.cfg.name + '-star-gradient)')
				.style('fill', '#fff')
				.style('fill-opacity', '0.1');
		} else {
			area = $pointsWrapper.select('.star-area')
				.datum(fakeData)
				.transition()
				.duration(_this.updateDuration)
				.attr('d', function(d) {
					return radarLine(d);
				});
		}


		// Grab rect sizes
		var fakeItems = d3Max([4, _this.cfg.total]);

		var areaRect = _this.drawFakeWeb(fakeData, 'star-fake-web', $axisWrapper);
		var maxRect = _this.drawFakeWeb(range(fakeItems).map(function() {
				return _this.maxValue
			}), 'star-max-web', $axisWrapper);
		var maxSize = d3Max([maxRect.height, maxRect.width]);

		// scale X and Y values
		const gScale = scaleLinear().range([0, 1]);
		const heightScale = gScale.domain([0, areaRect.height])(maxSize);
		const widthScale = gScale.domain([0, areaRect.width])(maxSize);
		// scale X and Y values

		// Gradient CX and CY values
		const center = 0.5;
		const cxRange = scaleLinear().range([0, center]).domain([maxRect.width, 0])(maxSize) + center;
		const cyRange = scaleLinear().range([0, center]).domain([maxRect.height, 0])(maxSize) + center;
		
		const initCXScale = scaleLinear().domain([maxSize, 0]).range([0, cxRange]);
		const initCYScale = scaleLinear().domain([maxSize, 0]).range([0, cyRange]);

		const diffX = Math.abs(maxRect.left - areaRect.left) * 2,
			cx = initCXScale(diffX);

		const diffY = Math.abs(maxRect.top - areaRect.top) * 2,
			cy = initCYScale(diffY);
		// Gradient CX and CY values


		radialGradient
			.transition()
			.duration(_this.updateDuration)
			.attrs({
				cx, cy,
				gradientTransform: function() {
					return 'scale(' + widthScale + ', ' + heightScale + ')';	
				},
			})
			.on('interrupt', function() {
				select(this)
					.attrs({
						cx, cy,
						gradientTransform: function() {
							return 'scale(' + widthScale + ', ' + heightScale + ')';
						},
					})
			});


		if (_this.cfg.init) {
			// Area intro
			area.style('fill-opacity', 0)
				.transition()
				.duration(_this.transitionDuration + _this.cfg.total * _this.cfg.stagger)
				.attrTween('stroke-dasharray', tweenDash)
				.on('interrupt', function() {
					select(this)
						.attr('stroke-dasharray', null)
						.style('fill-opacity', 0.1)
				})
				.transition()
				.duration(function() {
					if (options.state === 'galaxy') {
						return _this.updateDuration / 2;
					} else {
						return _this.updateDuration;
					}
				})
				.attr('stroke-dasharray', null)
				.style('fill-opacity', 0.1);
		}
	}

	function tweenDash() {
		var l = this.getTotalLength(),
			i = interpolateString('0,' + l, l + ',' + l);

		return function(t) {
			return i(t);
		};
	}

	function drawPoints(data) {
		var pointsContainer;

		if (_this.cfg.init) {
			pointsContainer = $pointsWrapper.append('g').attr('class', 'radar-circle-wrapper');
		} else {
			pointsContainer = $pointsWrapper.select('.radar-circle-wrapper');
		}

		var point = pointsContainer.selectAll('circle')
			.data(data);

		// create points group
		point.enter().append('circle')
			.attr('class', 'radar-circle')
			.on('mouseover', function(d) {
				var newCords = Tip.rotate(+select(this).attr('cx'), select(this).attr('cy'), _this.helpers.axeRotate(_this.cfg.total));

				var cx = newCords[0] + (_this.cfg.w / 2) + (_this.cfg.dotRadius * 2) + 2,
					cy = newCords[1] + (_this.cfg.w / 2);

				Tip.show.call(this, d, cx, cy, _this.cfg.w);
			})
			.on('mouseout', Tip.hide)
			.styles({
				fill: () => _this.getColor(0),
				'stroke-width': 2,
				stroke: () => _this.getColor(0),
				color: () => _this.getColor(0),
			})
			.attrs({
				cx: 0,
				cy: 0,
				r: _this.cfg.dotRadius,
			})
			.merge(point)
			.transition()
			.duration(_this.cfg.init ? _this.transitionDuration : _this.updateDuration)
			.delay(_this.cfg.init ? (d, i) => i * _this.cfg.stagger : 0)
			.styles({
				fill: d => _this.getColor(d[_this.keyName]),
				stroke: d => color(_this.getColor(d[_this.keyName])).brighter(0.5),
			})
			.attrs({
				cx: (d, i) => rScale(d[_this.keyName]) * Math.cos(_this.cfg.angleSlice * i - Math.PI / 2),
				cy: (d, i) => rScale(d[_this.keyName]) * Math.sin(_this.cfg.angleSlice * i - Math.PI / 2),
				r: _this.cfg.dotRadius,
			});

		point.exit()
			.transition()
			.duration(_this.updateDuration)
			.style('opacity', 0)
			.remove();
	}

	function moveToGuide() {
		if (_this.cfg.hasGuide) {
			var cx = +_this.cfg.starGuide.attr('cx'),
				cy = +_this.cfg.starGuide.attr('cy'),
				scale, size;

			_this.cfg.starGuide.text(function(d) {
					scale = d.data.scale.value;
					size = d.data.scale.size / 2;
				})
				.attr('data-star', _this.cfg.name);

			if (_this.cfg.hidden === 'hidden') {
				$starWrapper
					.style('opacity', function() {
						if (_this.cfg.state === 'hidden' && options.state !== 'galaxy') {
							return 0;
						} else {
							return 1;
						}
					})
					.attr('transform', `translate(${cx-size}, ${cy-size}) scale(${scale})`);
			} else {
				if (_this.cfg.init) {
					$starWrapper
						.style('opacity', function() {
							if (_this.cfg.state === 'hidden' && options.state !== 'galaxy') {
								return 0;
							} else {
								return 1;
							}
						})
						.attr('transform', `translate(${cx-size}, ${cy-size}) scale(${scale})`)
				} else {
					$starWrapper
						.transition()
						.duration(_this.updateDuration)
						.style('opacity', function() {
							if (_this.cfg.state === 'hidden' && options.state !== 'galaxy') {
								return 0;
							} else {
								return 1;
							}
						})
						.attr('transform', `translate(${cx-size}, ${cy-size}) scale(${scale})`);
				}
			}


		} else {
			$starWrapper.style('opacity', 1);
		}
	}

	function starAverageScor() {
		var sum = 0;

		_this.cfg.data.forEach(function(el) {
			sum += el[_this.keyName];
		});

		_this.cfg.averageValue = sum / _this.cfg.total;
	}

	function setDataConfig(data) {
		_this.cfg.allAxis = (data.map(function(d) {
			return d.axis;
		}));
		_this.cfg.total = _this.cfg.allAxis.length;
		_this.cfg.angleSlice = Math.PI * 2 / _this.cfg.total;
	}

	function setStarColor() {
		if (_this.cfg.hasGuide) {
			_this.cfg.starColor = _this.cfg.starGuide.attr('color');
		} else {
			_this.cfg.starColor = _this.getColor(_this.cfg.averageValue);
		}

		dummyStar.style('stroke', _this.cfg.starColor);
	}

	function findGuide() {
		var starName = _this.cfg.name.replace(/[0-9]+$/, '').replace(/\W/g, '_');

		_this.cfg.starGuide = select('#' + (starName + '-star-guide__' + _this.cfg.parentName).replace(/_+$/g, ''));
		_this.cfg.hasGuide = !!_this.cfg.starGuide.node();

		if (starName === 'core') {
			_this.cfg.starGuide = select('#core-star-guide');

			if (!_this.cfg.starGuide.node()) {
				_this.cfg.starGuide = select('.stars-guide').append('circle')
					.attr('class', 'star-guide')
					.attr('id', 'core-star-guide');
			}

			_this.cfg.starGuide.attrs({
				r: _this.cfg.w / 2,
				cx: _this.cfg.w / 2,
				cy: _this.cfg.w / 2, 
			});
			_this.cfg.hasGuide = false;
		}

		if (_this.cfg.parentName.length) {
			_this.cfg.parentScale = getTransformation(select('#' + _this.cfg.parentName + '-star').attr('transform')).scaleX;
			_this.cfg.selfLabel = _this.cfg.parentName + '-' + _this.cfg.name.replace(/[0-9]+$/, '') + '-label';
		}
	}

	function getStarPadding(newSize) {
		if (typeof _this.starPadding !== 'undefined') {
			return _this.starPadding;
		} else {
			var query = newSize ? newSize : _this.cfg.w;
			var starPadding;
			if (query <= 660) {
				starPadding = 30;
			} else {
				starPadding = 70;
			}

			return starPadding;
		}
	}

	function breakPointStyles(newSize) {
		var query = newSize ? newSize : _this.cfg.w;
		if (query <= 660) {
			_this.cfg.sliceHeight = 40;
			_this.cfg.triangleSize = 100;
			_this.cfg.labelFz = 13;
			_this.cfg.dotRadius = 7;
			// _this.cfg.starPadding = 30;
		} else {
			_this.cfg.sliceHeight = 60;
			_this.cfg.triangleSize = 200;
			_this.cfg.labelFz = 17;
			_this.cfg.dotRadius = 9;
			// _this.cfg.starPadding = 70;
		}
	}

	var starLabel = {
		gutterLeft: 32,
		gutterRight: 45,
		topGutter: 7,
		mb: 16,
		cl: 'star-label',
		r: 8,

		gutter: function gutter() {
			return this.gutterLeft + this.gutterRight;
		},

		draw: function draw(starTip) {
			var self = this;
			this.labelG = $starWrapper.insert('g', ':first-child')
				.attr('class', self.cl)
				.style('opacity', '0');


			// draw, style and get boundingBox of text
			this.txt = this.labelG.append('text')
				.text(_this.cfg.humanName.replace(/_/g, ' '))
				.attr('class', self.cl + '__text')
				.attr('text-anchor', 'middle');

			var dummy = select('body').append('p')
				.attr('class', self.cl + '-dummy')
				.text(_this.cfg.humanName.replace(/_/g, ' '));
			var boundRect = dummy.node().getBoundingClientRect();
			dummy.remove();
			this.txt.attr('x', (boundRect.width / 2) + self.gutterLeft);
			this.txt.attr('y', (boundRect.height / 2));



			// draw and style rect
			this.rectHeight = boundRect.height + (self.topGutter * 2);
			this.rectWidth = boundRect.width + self.gutter();
			this.rect = this.labelG.insert('rect', ':first-child')
				.attrs({
					class: self.cl + '__rect',
					rx: self.rectHeight / 2,
					ry: self.rectHeight / 2,
				}).attrs({
					height: self.rectHeight,
					y: function() {
						return -boundRect.height;
					},
					width: self.rectWidth
				});



			/*===================================================
			=            Draw and style label action            =
			===================================================*/

			this.actionG = this.labelG.append('g')
				.attr('transform', function() {
					return 'translate(' + (self.rectWidth - self.r - 6) + ', ' + 1 + ')';
				})
				.style('pointer-events', 'all');

			this.actionG.append('circle')
				.attrs({
					'class': self.cl + '__circle',
					r: self.r
				});

			this.actionG.append('use')
				// .style('pointer-events', 'all')
				.attr('xlink:href', '#help');

			this.actionG
				.on('mouseover', function() {
					var rect = this.getBoundingClientRect();
					starTip.init({
						title: _this.cfg.humanName,
						content: _this.cfg.tooltipContent
					}, rect);
				})
				.on('mouseleave', starTip.hide);

			/*=====  End of Draw and style label action  ======*/



			// set group scale
			var labelScale = 1;
			self.starScale = 1;
			if (_this.cfg.hasGuide) {
				_this.cfg.starGuide.text(function(d) {
					labelScale = d.data.scale.label;
					self.starScale = d.data.scale.value;
				});
			}

			this.initialScale = labelScale * 2;

			this.labelG.attr('transform', function() {
				var y = ((self.rectHeight / 2) + self.mb) * self.initialScale;
				var x = (_this.cfg.size / 2) - (self.rectWidth / 2 * self.initialScale);

				return 'translate(' + (x) + ', ' + (-y) + ') scale(' + self.initialScale + ')';
			});
		},
		show: function show() {
			this.labelG
				.style('display', 'block')
				.interrupt()
				.transition()
				.duration(_this.updateDuration)
				.style('opacity', 1);
		},
		hide: function hide() {
			this.labelG
				.interrupt()
				.transition()
				.duration(_this.updateDuration)
				.style('opacity', 0)
				.style('display', 'none');
		},
		update: function update() {
			var labelScale = 1;
			this.starScale = 1;

			if (_this.cfg.hasGuide) {
				_this.cfg.starGuide.text(function(d) {
					labelScale = d.data.scale.label;
					_this.starScale = d.data.scale.value;
				});
			}

			this.initialScale = labelScale * 2;

			this.labelG
				.transition()
				.duration(_this.updateDuration)
				.attr('transform', function() {
					var y = ((this.rectHeight / 2) + this.mb) * this.initialScale;
					var x = (_this.cfg.size / 2) - (this.rectWidth / 2 * this.initialScale);

					return 'translate(' + (x) + ', ' + (-y) + ') scale(' + this.initialScale + ')';
				}.bind(this));
		}
	};

	/*=====  End of Define star methods  ======*/



	/*=========================================================
	=            Prepare groups, scales and radius            =
	==========================================================*/

	var outerRadius = _this.cfg.w / 2,
		innerRadius = outerRadius - _this.cfg.sliceHeight,

		pie, arc, arcs, labelArc,
		radialGradient;

	var rScale = scaleLinear()
		.range([0, innerRadius - _this.cfg.sliceHeight])
		.domain([0, _this.maxValue]);

	_this.rScale = rScale;


	var $starWrapper = targetSelector.insert('g', ':first-child')
		.attrs({ class: 'star', id: _this.cfg.name + '-star' })
		.style('opacity', 0),
		$starBody = $starWrapper.append('g'),
		$dataWrapper = $starBody.append('g').attr('mask', 'url(#' + 'star-mask)'),
		$axisWrapper = $dataWrapper.append('g').attr('class', 'axis-wrapper'),
		$pointsWrapper = $dataWrapper.append('g').attr('class', 'points-wrapper'),
		$starSlices = $starBody.append('g').attr('class', 'star-slices'),
		symbolArc = d3Arc(),
		dummyStar = $starWrapper.insert('circle', ':first-child')
		.attrs({
			'r': outerRadius,
			'cx': outerRadius,
			'cy': outerRadius,
			'class': 'star__dummy'
		})
		.styles({
			fill: 'none'
		})
		.on('mouseover', function() {
			if (_this.cfg.isPreview) {
				select('#' + _this.cfg.selfLabel).classed('is-focused', true);
				$starWrapper.classed('is-focused', true);

				dummyStar.attr('filter', 'url(#star-guide-glow)');

				_this.qa.init(
					_this.cfg.name,
					_this.cfg.starGuide.node().getBoundingClientRect(),
					select('#' + _this.cfg.parentName + '-star').select('.star__dummy').node().getBoundingClientRect()
				);
			}
		})
		.on('mouseleave', function() {
			if (_this.cfg.isPreview) {
				select('#' + _this.cfg.selfLabel).classed('is-focused', false);
				$starWrapper.classed('is-focused', false);
			}

			_this.qa.slowHide();
		});
	
	$starWrapper
		.on('click', function() {
			if (_this.cfg.isPreview) {
				focus.star(_this.cfg.name);
				select('#' + _this.cfg.selfLabel).classed('is-focused', false);
				$starWrapper.classed('is-focused', false);

				// hasher.setHash(_this.cfg.name); 
				if (typeof _this.onStarClick === 'function') {
					_this.onStarClick(_this.cfg);
				}
			}
		});

	_this.mask.attr('r', (_this.cfg.w / 2) - _this.cfg.sliceHeight);


	/*=====  End of Prepare groups, scales and radius  ======*/



	/*=======================================
	=            Bind UI actions            =
	=======================================*/

	var Tip = starTip.call(null, $starWrapper);

	findGuide();
	setDataConfig(data);
	starAverageScor();
	setStarColor();

	drawPie(data);

	drawGradient();
	addAxes(data);

	drawArea(data);
	drawPoints(data);

	// Rotate axis group to pie slices position
	$dataWrapper.attr('transform', function() {
		return 'translate(' + (outerRadius) + ',' + (outerRadius) + ') rotate(' + _this.helpers.axeRotate(_this.cfg.total) + ')';
	});

	moveToGuide();
	starLabel.draw(_this.starTooltip);
	$axisWrapper.style('display', 'none');

	this.update = function update(data) {
		_this.sliceQa.destroy();
		
		$starWrapper.style('display', 'block');
		$axisWrapper.style('display', 'block');
		_this.cfg.init = false;

		$dataWrapper.attr('transform', function() {
			return 'translate(' + (outerRadius) + ',' + (outerRadius) + ') rotate(0)';
		});

		_this.cfg.data = data;
		findGuide();
		setDataConfig(data);

		starAverageScor();
		setStarColor();

		drawPie(data);

		addAxes(data);
		drawArea(data);
		drawPoints(data);

		_this.drawStarGuide();
		starLabel.update();

		if (_this.cfg.isPreview) {
			resp.preview.updateColors();
		}

		$dataWrapper.attr('transform', function() {
			return 'translate(' + (outerRadius) + ',' + (outerRadius) + ') rotate(' + _this.helpers.axeRotate(_this.cfg.total) + ')';
		});

		_this.cfg.isResizing = false;
		$axisWrapper.style('display', 'none');
		if (_this.cfg.state === 'hidden') {
			$starWrapper.style('display', 'none');
		}
	};

	this.resize = function resize() {
		_this.cfg.isResizing = true;
		_this.sliceQa.destroy();

		var startSize = _this.helpers.getNewSize(_this.cGuide);
		_this.cfg.starPadding = getStarPadding(startSize);

		var newSize = _this.helpers.getNewSize(_this.cGuide, _this.cfg.starPadding);

		breakPointStyles(newSize);

		if (newSize === _this.cfg.w) {
			return;
		}

		_this.cfg.w = newSize;
		_this.cfg.h = newSize;

		// update star mask
		_this.mask.attr('r', (_this.cfg.w / 2) - _this.cfg.sliceHeight);

		outerRadius = _this.cfg.w / 2;
		innerRadius = outerRadius - _this.cfg.sliceHeight;
		rScale.range([0, innerRadius - _this.cfg.sliceHeight]);

		dummyStar.attrs({
			'r': outerRadius,
			'cx': outerRadius,
			'cy': outerRadius
		});

		_this.update(_this.cfg.data);
	};

	this.drawStarGuide = function drawStarGuide() {
		var newData = d3Pie()
			.value(function() {
				return 1;
			}).apply(null, [_this.cfg.data]);


		_this.cfg.edgePos = _this.cfg.w / 1.2;
		var guideArc = d3Arc()
			.innerRadius(_this.cfg.edgePos)
			.outerRadius(_this.cfg.edgePos);

		/*
		qaEdge - edge of the quick action from chart slice
		 */
		var qaEdge = 46;
		var qaArc = d3Arc()
			.innerRadius(outerRadius + qaEdge)
			.outerRadius(outerRadius + qaEdge);

		if (_this.cfg.init) {
			_this.cfg.guides = [];
		}

		if (!_this.cfg.isResizing) {
			_this.cfg.guides.forEach(function(d) {
				select(d).remove();
			});
			_this.cfg.guides = [];
		}

		function getScaledCenter(centroid, axis, sSize, sValue) {
			return (+_this.cfg.starGuide.attr(axis) - (sSize / 2)) + (sValue * centroid);
		}
		newData.forEach(function(d) {
			var starGuideID = d.data.axis.toLowerCase().replace(/\W/g, '_') + '-star-guide__' + _this.cfg.name,
				scaledSize = _this.cfg.w,
				scaledValue,
				starGuide;

			if (d.data.isChild) {

				if (select('#' + starGuideID).node()) {
					starGuide = select('.stars-guide').select('#' + starGuideID);
				} else {
					starGuide = select('.stars-guide').append('circle').attr('class', 'star-guide');
					_this.cfg.guides.push(starGuide.node());
				}

				if (_this.cfg.hasGuide) {
					_this.cfg.starGuide.text(function(d) {
						scaledSize = d.data.scale.size;
						scaledValue = d.data.scale.value;
					});
				}

				d.data.scale = _this.getChildScale(d.data[_this.keyName], _this.cfg.w, scaledSize);

				// var cx, cy;
				starGuide.datum(d)
					.attrs({
						r: function(d) {
							return d.data.scale.size / 2;
						},
						color: _this.getColor(d.data[_this.keyName]),
						cx: function(d) {
							var c = guideArc.centroid(d)[0] + outerRadius;

							if (_this.cfg.hasGuide) {
								c = getScaledCenter(c, 'cx', scaledSize, scaledValue);
							}
							// cx = c;
							return c;
						},
						cy: function(d) {
							var c = guideArc.centroid(d)[1] + outerRadius;

							if (_this.cfg.hasGuide) {
								c = getScaledCenter(c, 'cy', scaledSize, scaledValue);
							}
							// cy = c;
							return c;
						},
						id: starGuideID
					}).style('opacity', 0);
			}


			if (d.data.qa) {
				var n = d.data.qa.length,
					split = (d.endAngle - d.startAngle) / n;

				d.data.qa.forEach(function(qaData, i) {
					var id = starGuideID + '-qa' + i;
					var qaGuide = select('#' + id);

					if (!qaGuide.node()) {
						qaGuide = select('.stars-guide').append('circle').attr('id', id);
					}
					qaData.id = id;

					var c = qaArc.centroid({
						padAngle: d.padAngle,
						value: 1,
						startAngle: d.startAngle + (i * split),
						endAngle: d.startAngle + ((i + 1) * split)
					});

					qaGuide.attrs({
						r: 1,
						cx: c[0] + outerRadius,
						cy: c[1] + outerRadius
					}).style('opacity', 0);
				});
			}
		});

		if (!_this.cfg.init) {
			moveToGuide();
		}
	};

	resp.resize = this.resize;
	resp.update = this.update;

	this.drawStarGuide();

	resp.preview = {
		updateColors: function() {
			arcs.selectAll('.star__arc')
				.transition()
				.duration(_this.updateDuration)
				.style('fill', _this.cfg.starColor);
		},
		on: function(scaleVal) {
			$starBody.style('pointer-events', 'none');
			$starWrapper.classed('is-preview', true);
			$starWrapper.selectAll('.star__label').style('display', 'none');

			_this.cfg.isPreview = true;
			_this.cfg.state = 'preview';
			// hide labels
			arcs.selectAll('text')
				.transition()
				.duration(_this.updateDuration)
				.style('opacity', 0);

			arcs.selectAll('.star__arc')
				.classed('is-preview', true)
				.transition()
				.duration(_this.updateDuration)
				.style('fill', _this.cfg.starColor);

			$starWrapper.selectAll('.star__slice');

			$starWrapper.style('cursor', 'pointer');

			this.timeOut = setTimeout(function() {
				$starWrapper.selectAll('.radar-circle').attr('filter', null);
			}, _this.updateDuration);

			starLabel.show(scaleVal);
		},
		off: function() {
			$starBody.style('pointer-events', null);
			clearTimeout(this.timeOut);
			dummyStar.attr('filter', null);

			$starWrapper.classed('is-preview', false);
			_this.cfg.isPreview = false;
			// show labels
			$starSlices.selectAll('text')
				.transition()
				.duration(_this.updateDuration)
				.style('opacity', 1);

			$starSlices.selectAll('.star__arc')
				.transition()
				.duration(_this.updateDuration)
				.style('fill', _this.cfg.mainColor)
				.call(tr => select(tr.node()).classed('is-preview', false))
				.attr('style', null)
				.on('end', function() {
					select(this)
						.classed('is-preview', false)
						.attr('style', null);
				});

			$starWrapper.style('cursor', 'auto');

			starLabel.hide();
		}
	};

	resp.hide = function hide(init) {
		_this.cfg.state = 'hidden';

		if (init) {
			$starWrapper.style('opacity', 0).style('display', 'none');
			$starWrapper.selectAll('.star__label').style('display', 'none');
			$starWrapper.selectAll('.radar-circle').attr('filter', null);
		} else {
			$starWrapper
				.transition()
				.duration(_this.updateDuration)
				.style('opacity', 0)
				.on('end', function() {
					select(this).style('dispaly', 'none');
					select(this).selectAll('.star__label').style('display', 'none');
					select(this).selectAll('.radar-circle').attr('filter', null);
				});
		}

	};
	resp.show = function show(isFocused) {
		$starWrapper
			.style('display', 'block')
			.transition()
			.duration(_this.updateDuration)
			.style('opacity', 1);

		if (isFocused) {
			$starWrapper.selectAll('.star__label').style('display', 'block');
		}
	};

	resp.getGalaxyData = function getGalaxyData() {
		_this.cfg.state = 'galaxy';

		var data = resp.getFocusData();
		data.rect = _this.getGalaxySize(data.rect);

		return data;
	};

	resp.getFocusData = function getFocusData() {
		_this.cfg.state = 'star';
		var list = [],
			rect = null;
		_this.cfg.guides.forEach(function(el) {
			var name = el.getAttribute('data-star');
			list.push(name);
		});


		rect = _this.cfg.starGuide.node().getBoundingClientRect();

		$starWrapper.selectAll('.radar-circle').attr('filter', 'url(#star-point-glow)');

		return {
			rect: rect,
			size: _this.cfg.w,
			list: list
		};
	};

	resp.destroy = function destroy() {
		// remove star guides
		_this.cfg.guides.forEach(function(d) {
			select(d).remove();
		});
		$starWrapper.transition()
			.duration(_this.updateDuration)
			.style('opacity', 0);

		_this.sliceQa.destroy();

		// remove star
		setTimeout(function() {
			$starWrapper.remove();
		}, _this.updateDuration);
	}.bind(this);

	resp.state = {
		get: function get() {
			return _this.cfg.state;
		},
		set: function set(newState) {
			_this.cfg.state = newState;
		}
	};
	resp.parent = _this.cfg.parentName;

	resp.color = _this.cfg.starColor;
	resp.guides = _this.cfg.guides;

	return resp;
}


/*=======================================
=            Star prototypes            =
=======================================*/

StarChart.prototype.helpers = {
	rotateAngle: function(d) {
		var angle = (d.startAngle + d.endAngle) * 90 / Math.PI;
		return angle;
	},

	getNewSize: function(el, starPadding) {
		return d3Min([el.height(), el.width()]) - (starPadding ? starPadding * 2 : 0);
	},

	centerRadius: function(inn, out) {
		return (inn / 2) + (out / 2);
	},

	axeRotate: function(total) {
		if (total > 1) {
			return (360 / total) / 2;
		}

		return 0;
	},

	bottomRadius: function(height, inn, out, sliceHeight) {
		return inn / 2 + out / 2 - sliceHeight / 2 - height + 1;
	},
	getTarget: function(guides, axis) {
		var target = false;
		guides.forEach(function(el) {
			var id = el.getAttribute('data-star');
			if (id && !!id.match(axis)) {
				target = id;
			}
		});

		return target;
	},
	getQaOrientation: function(d) {
		var startAngle = Math.floor((180 / Math.PI * (d.startAngle + d.endAngle) / 2));
		if (startAngle > 180) {
			return 'left';
		} else {
			return 'right';
		}
	},
	checkOrientation: function(d) {
		var temp = (180 / Math.PI * (d.startAngle + d.endAngle));
		var startAngle = Math.floor(temp / 2);
		if ((
			startAngle > 90 && 
			startAngle < 270 && 
			temp !== 360
			) || startAngle === 180
		) {
			return '75%';
		} else {
			return '25%';
		}
	}
};

StarChart.prototype.drawAxes = function(data) {
	var _this = this,
		webColor = '#3a64a5';
	var $axisWrapper = select('#axes-count-' + data.length);
	if (!$axisWrapper.node()) {
		$axisWrapper = this.defs.select('.star-axes').append('g').attr('id', 'axes-count-' + data.length);


		/*=================================
		=            Draw axes            =
		=================================*/

		$axisWrapper.selectAll('line')
			.data(data)
			.enter()
			.append('line')
			.attrs({
				x1: 0,
				y1: 0,
				x2: 0,
				y2: 0
			})
			.attr('class', 'line')
			.style('stroke', webColor)
			.style('stroke-width', 1);

		/*=====  End of Draw axes  ======*/



		/*===================================
		=            Draw levels            =
		===================================*/

		for (var i = 1; i < _this.cfg.levels + 1; i++) {
			$axisWrapper.append('path')
				.datum(data)
				.attr('class', 'star-level')
				.style('stroke-width', 1)
				.style('stroke', webColor)
				.style('fill', 'none');
		}

		/*=====  End of Draw levels  ======*/
	}

	/*
	Update lines
	 */
	$axisWrapper.selectAll('line').transition()
		.duration(_this.updateDuration)
		.attrs({
			x2: function(d, i) {
				return _this.cfg.scaleVal * Math.cos(_this.cfg.angleSlice * i - Math.PI / 2);
			},
			y2: function(d, i) {
				return _this.cfg.scaleVal * Math.sin(_this.cfg.angleSlice * i - Math.PI / 2);
			}
		});

	/*
	Update levels
	 */
	var webLine = radialLine()
		.curve(curveLinearClosed)
		// .interpolate('linear-closed')
		.angle(function(d, i) {
			return i * _this.cfg.angleSlice;
		});

	$axisWrapper.selectAll('.star-level')
		.each(function(d, i) {
			var value = _this.maxValue / _this.cfg.levels * ++i;
			webLine.radius(function(val) {
				return _this.rScale(val);
			}.bind(null, value));

			select(this)
				.transition(_this.updateDuration)
				.attr('d', webLine(d));
		});
};

// 2x star size
StarChart.prototype.galaxySize = 2;
StarChart.prototype.getGalaxySize = function(rect) {
	var substract = rect.width / this.galaxySize;

	return {
		bottom: rect.bottom + substract,
		height: rect.height * this.galaxySize,
		left: rect.left - substract,
		right: rect.right + substract,
		top: rect.top - substract,
		width: rect.width * this.galaxySize
	};
};

/**
 * Draw fake webs to handle the gradient scale and centering
 * @param  {array} fakeData  [data to be used on web creation]
 * @param  {string} fakeClass [class string used to find the web in update methods]
 * @return {object}           [return getBoundingClientRect object]
 */
StarChart.prototype.drawFakeWeb = function(fakeData, fakeClass, wrapper) {
	var _this = this,
		angle = Math.PI * 2 / fakeData.length;

	var webLine = radialLine()
		.curve(curveLinearClosed)
		.radius(function(d) {
			return _this.rScale(d);
		})
		.angle(function(d, i) {
			return i * angle;
		});

	var path;

	if (this.cfg.init) {
		path = wrapper.append('path')
			.attr('class', fakeClass)
			.styles({
				stroke: '#fff',
				'stroke-width': 0,
				fill: 'none',
				// 'pointer-events': 'all'
			});
	} else {
		path = wrapper.select('.' + fakeClass);
	}


	path.datum(fakeData)
		.attr('d', function(fakeData) {
			return webLine(fakeData);
		});

	return path.node().getBoundingClientRect();
};

StarChart.prototype.getChildScale = function(score, startSize, starSize) {
	var scale = scaleLinear()
		.range(this.childScale)
		.domain([0, this.maxValue]);

	var substract = scale(score),
		scaledSize = (substract / 100) * starSize,
		scaleVal = ((scaledSize * 100) / startSize) / 100;

	// get star label scale
	var labelScale = 100 / (scaledSize * 100 / starSize);

	return {
		size: scaledSize,
		value: scaleVal,
		maxSize: (scale(0) / 100) * starSize,
		label: labelScale
	};
};

StarChart.prototype.maxValue = 10;
StarChart.prototype.childScale = [40, 20];
StarChart.prototype.keyName = 'value';

/*=====  End of Star prototypes  ======*/

export default StarChart;
