import React, { Component } from "react";
import { Redirect, Route, Switch, Router } from "react-router-dom";
import "./App.css";
import Password from "./containers/create-new-password";
import ForgotContainer from "./containers/forgot-container";
import InApp from "./containers/inner-app";
import LoginPageContainer from "./containers/login-page-container";
import RegistrationContainer from "./containers/registration-container";
import NotFoundOrLoggedIn from "./components/not-found/not-found-out-of-app";
import { Provider } from "react-redux";
import store from "./store";
import PrivateRoute from "../src/components/privateRoute/privateRoute";
import history from "./history";
import StarPageContainer from "./containers/star-page-container";

class App extends Component {
  public svg = require("./assets/_svgSprite.svg");
  public render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route path="/login" component={LoginPageContainer} />
            <Route
              path="/registration-page"
              component={RegistrationContainer}
            />
            <Route path="/forgot-password" component={ForgotContainer} />
            <Route path="/reset-password" component={Password} />
            <PrivateRoute path="/in-app" component={InApp} />
            <Route exact={true} path="/" component={LoginPageContainer} />
            <Route path="/not-found" component={NotFoundOrLoggedIn} />
            <Redirect to="/not-found" />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
