import { auth, storage } from "../firebase";
import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import history from "../history";
import { object } from "prop-types";

export interface IUser {
  uid: string | null;
  displayName: string | null;
  photoURL: string | null;
  email: string;
  emailVerified: boolean;
  phoneNumber: null;
  isAnonymous: boolean;
}

// action types
export const SIGNING_IN = "SIGNING_IN";
export const SIGNING_IN_SUCCESS = "SIGNING_IN_SUCCESS";
export const SIGNING_IN_FAILURE = "SIGNING_IN_FAILURE";
export const SIGN_OUT = "SIGN_OUT";
export const CREATING_USER = "CREATE_USER";
export const UPDATING_NAME = "UPDATE_NAME";
export const UPDATING_EMAIL = "UPDATE_EMAIL";
export const UPDATE_PICTURE_SUCCESS = "UPDATE_PICTURE_SUCCESS";

// action creator interfaces
export interface ISigningIn {
  type: typeof SIGNING_IN;
}
export interface ISigningInSuccess {
  type: typeof SIGNING_IN_SUCCESS;
  user: any;
}
export interface ISigningInFailure {
  type: typeof SIGNING_IN_FAILURE;
}
export interface ISignOut {
  type: typeof SIGN_OUT;
}
export interface ICreateUser {
  type: typeof CREATING_USER;
}
export interface IUpdateName {
  type: typeof UPDATING_NAME;
}
export interface IUpdateEmail {
  type: typeof UPDATING_EMAIL;
}
export interface IUpdatePictureSuccess {
  type: typeof UPDATE_PICTURE_SUCCESS;
}

// action creators
export const signingIn = (): ISigningIn => ({
  type: SIGNING_IN
});
export const signingInSuccess = (user: object): ISigningInSuccess => ({
  type: SIGNING_IN_SUCCESS,
  user
});
export const signingInFailure = (): ISigningInFailure => ({
  type: SIGNING_IN_FAILURE
});
export const signOut = (): ISignOut => ({
  type: SIGN_OUT
});
export const creatingUser = (): ICreateUser => ({
  type: CREATING_USER
});
export const updatingName = (): IUpdateName => ({
  type: UPDATING_NAME
});
export const updatingEmail = (): IUpdateEmail => ({
  type: UPDATING_EMAIL
});
export const UpdatePictureSuccess = (): IUpdatePictureSuccess => ({
  type: UPDATE_PICTURE_SUCCESS
});

type IUserActions =
  | ISigningIn
  | ISigningInSuccess
  | ISigningInFailure
  | ISignOut
  | ICreateUser
  | IUpdateName
  | IUpdateEmail
  | IUpdatePictureSuccess;

export interface IUserState {
  user: any;
  error: null | string;
  loading: boolean;
  email: string;
  password: string;
}

// reducer with initial state
const initialState: IUserState = {
  user: null,
  error: null,
  loading: false,
  email: "",
  password: ""
};

type ThunkResult<R> = ThunkAction<R, IUserState, null, IUserActions>;

export const verifyAuth = (): ThunkResult<void> => dispatch => {
  auth.onAuthStateChanged(user => {
    if (user) {
      dispatch(signingInSuccess(user));
      history.push("/in-app");
    } else {
      dispatch(signOut());
    }
  });
};

export const signingOut = () => {
  auth.signOut();
  alert("Thanks! See you soon!");
};

export const authenticateUser = (
  email: string,
  password: string
): ThunkResult<void> => {
  return dispatch => {
    dispatch(signingIn());
    auth
      .signInWithEmailAndPassword(email, password)
      .catch(error => {alert('Your Email or Password are incorrect, please try again')});
  };
};

export const updateName = (newName: string): ThunkResult<void> => {
  // auth stuff in firebase.ts file
  return dispatch => {
    dispatch(updatingName());
    if (auth.currentUser) {
      auth.currentUser
        .updateProfile({
          displayName: newName
        })
        .then(() => {
          if (auth.currentUser) {
            dispatch(signingInSuccess(auth.currentUser));
          }
        })
        .catch(error => {
          alert(
            "There was an error updating your account, please login & try again"
          );
        });
    }
  };
};

export const updateEmail = (newEmail: string): ThunkResult<void> => {
  // auth stuff in firebase.ts file
  return dispatch => {
    dispatch(updatingEmail());
    if (auth.currentUser) {
      auth.currentUser
        .updateEmail(newEmail)
        .then(() => {
          if (auth.currentUser) {
            dispatch(signingInSuccess(auth.currentUser));
          }
        })
        .catch(error => {
          alert(
            "There was an error updating your account, please login & try again"
          );
        });
    }
  };
};

export const updatePicture = (newPic: string): ThunkResult<void> => {
  // auth stuff in firebase.ts file
  return dispatch => {
    dispatch(UpdatePictureSuccess());
    if (auth.currentUser) {
      auth.currentUser
        .updateProfile({
          photoURL: newPic
        })
        .then(() => {
          if (auth.currentUser) {
            dispatch(signingInSuccess(auth.currentUser));
          }
        })
        .catch(error => {
          alert(
            "There was an error updating your account, please login & try again"
          );
        });
    }
  };
};

export const createUser = (
  email: string,
  password: string,
  name: string
): ThunkResult<void> => {
  return dispatch => {
    auth
      .createUserWithEmailAndPassword(email, password)
      .then(user => {
        if (user.user) {
          user.user.sendEmailVerification();
          user.user
            .updateProfile({ displayName: name })
            .then(() => signingInSuccess(user.user!));
        }
      })
      .catch(error => {
        alert(
          "There was an error registering your account, please check if your email is already registered with us. Thank you."
        );
      });
  };
};

export const userReducer = (state = initialState, action: IUserActions) => {
  switch (action.type) {
    case SIGNING_IN:
      return { ...state, loading: true, error: null };
    case SIGNING_IN_SUCCESS:
      return { ...state, loading: false, error: null, user: action.user };
    case SIGNING_IN_FAILURE:
      return { ...state, loading: false, error: "Failed to sign in" };
    case SIGN_OUT:
      return { ...state, user: null };
    case CREATING_USER:
      return { ...state };
    case UPDATING_NAME:
      return { ...state };
    case UPDATE_PICTURE_SUCCESS:
      return { ...state, loading: false, error: null };
    default:
      return state;
  }
};

export default userReducer;
