export interface IQuestion {
  question: string;
  category: string;
  id: number;
  value: number;
  isAnswered: boolean;
}

export const allQuestions: IQuestion[] = [
  {
    question:
      "I regularly take the time I need to experience play, adventure & leisure.",
    category: "Fun & Leisure",
    id: 0,
    value: 0,
    isAnswered: false
  },
  {
    question: "I love & make the most of the time I spend with my friends.",
    category: "Friends",
    id: 12,
    value: 0,
    isAnswered: false
  },
  {
    question: "I create romance in my life.",
    category: "Relationships",
    id: 2,
    value: 0,
    isAnswered: false
  },
  {
    question: "I enjoy my work environment & the people with whom I work.",
    category: "Career",
    id: 3,
    value: 0,
    isAnswered: false
  },
  {
    question: "I am free from worry & anxiety about money.",
    category: "Money",
    id: 5,
    value: 0,
    isAnswered: false
  },
  {
    question:
      "I regularly engage in activities & learning that grow & expand me.",
    category: "Personal Growth",
    id: 6,
    value: 0,
    isAnswered: false
  },
  {
    question:
      "I have created the experience of family in my life, whether or not it is with my biological relatives.",
    category: "Family",
    id: 8,
    value: 0,
    isAnswered: false
  },
  {
    question: "I am surrounded by things that I love & have meaning to me.",
    category: "Physical Environment",
    value: 0,
    id: 13,
    isAnswered: false
  },
  {
    question:
      "I approach my health in a proactive & generative way, rather than crisis management mode.",
    category: "Health & Wellbeing",
    id: 17,
    value: 0,
    isAnswered: false
  },
  {
    question:
      "I create plenty of space in my life to relax & enjoy myself & others.",
    category: "Fun & Leisure",
    id: 1,
    value: 0,
    isAnswered: false
  },
  {
    question: "I love my work.",
    category: "Career",
    id: 4,
    value: 0,
    isAnswered: false
  },
  {
    question:
      "I am free from past resentments or blame in the area of intimate relationships.",
    category: "Relationships",
    id: 7,
    value: 0,
    isAnswered: false
  },
  {
    question: "I feel my talents & skills are well used in my work.",
    category: "Career",
    id: 9,
    value: 0,
    isAnswered: false
  },
  {
    question:
      "I have enough money to do the things I want to do & to accomplish the things that are important to me.",
    category: "Money",
    id: 10,
    value: 0,
    isAnswered: false
  },
  {
    question:
      "I am satisfied with the role I play & the level of contribution I have in my family.",
    category: "Family",
    id: 11,
    value: 0,
    isAnswered: false
  },
  {
    question:
      "I regularly experience living a life that I love & loving who I am becoming.",
    category: "Personal Growth",
    id: 14,
    value: 0,
    isAnswered: false
  },
  {
    question: "My friendships nourish & sustain me.",
    category: "Friends",
    id: 15,
    value: 0,
    isAnswered: false
  },
  {
    question: "I am satisfied with the level of contact I have with my family.",
    category: "Family",
    id: 16,
    value: 0,
    isAnswered: false
  },
  {
    question: "My financial future feels robust & sustainable.",
    category: "Money",
    id: 18,
    value: 0,
    isAnswered: false
  },
  {
    question:
      "My wardrobe is a clear expression of who I am. I love being in the clothes I wear.",
    category: "Physical Environment",
    id: 19,
    value: 0,
    isAnswered: false
  },
  {
    question: "I have a sufficient number of great friends.",
    category: "Friends",
    id: 20,
    value: 0,
    isAnswered: false
  },
  {
    question: "I am open to creating an intimate loving relationship.",
    category: "Relationships",
    id: 21,
    value: 0,
    isAnswered: false
  },
  {
    question:
      "I have support systems & structures in place that allow me to easily maintain my health & well being.",
    category: "Health & Wellbeing",
    id: 22,
    value: 0,
    isAnswered: false
  },
  {
    question: "I feel like I have found my right livelihood.",
    category: "Career",
    id: 23,
    value: 0,
    isAnswered: false
  },
  {
    question:
      "Nothing feels hidden or witheld in my relationships with family members.",
    category: "Family",
    id: 24,
    value: 0,
    isAnswered: false
  },
  {
    question:
      "The level of order in my surroundings is appropriate to my needs (it serves me).",
    category: "Physical Environment",
    id: 25,
    value: 0,
    isAnswered: false
  },
  {
    question:
      "I am conscious of my body & fitness level & take responsibility for my physical well-being.",
    category: "Health & Wellbeing",
    id: 26,
    value: 0,
    isAnswered: false
  },
  {
    question: "I see opportunity for growth & development in my position.",
    category: "Personal Growth",
    id: 27,
    value: 0,
    isAnswered: false
  },
  {
    question:
      "I know what works for me to maintain my health & I consistently do it.",
    category: "Health & Wellbeing",
    id: 28,
    value: 0,
    isAnswered: false
  },
  {
    question: "I trust the relationships I have with my friends.",
    category: "Friends",
    id: 29,
    value: 0,
    isAnswered: false
  },
  {
    question: "I am satisfied with my level of vitality & well being.",
    category: "Health & Wellbeing",
    id: 30,
    value: 0,
    isAnswered: false
  },
  {
    question: "I feel nourished & supported by my home.",
    category: "Physical Environment",
    id: 31,
    value: 0,
    isAnswered: false
  },
  {
    question: "I am willing to risk myself for the sake of intimacy.",
    category: "Relationships",
    id: 32,
    value: 0,
    isAnswered: false
  },
  {
    question:
      "I have a belief system that sustains me no matter what circumstances life throws at me.",
    category: "Personal Growth",
    id: 33,
    value: 0,
    isAnswered: false
  },
  {
    question:
      "I am engaged in the unfolding story of my life & approach each day as an adventure.",
    category: "Personal Growth",
    id: 34,
    value: 0,
    isAnswered: false
  },
  {
    question: "I create fun for myself & others.",
    category: "Fun & Leisure",
    id: 35,
    value: 0,
    isAnswered: false
  },
  {
    question: "I am a good friend & I make myself available to my friendships.",
    category: "Friends",
    id: 36,
    value: 0,
    isAnswered: false
  },
  {
    question: "I manage my money & financial affairs & records well.",
    category: "Money",
    id: 37,
    value: 0,
    isAnswered: false
  },
  {
    question:
      "I know what activities renew me & bring me alive & I participate in them regularly.",
    category: "Fun & Leisure",
    id: 38,
    value: 0,
    isAnswered: false
  }
];

export default allQuestions;
