import * as React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/logos/navLogos/northstar-logo.png";
import styles from "./registration.module.scss";
import PrimaryButton from "../primaryButton/primaryButton";
import TextInput from "../textBox/textInput/textInput";
import SignUpButton from "../sign-up-submit-button/sign-up-submit-button";
import SignUpButtonDefunct from "../sign-up-submit-button/sign-up-button-defunct";
// import { setRegistrationDetails } from "../../reducer/registrationReducer";
import { IStore } from "../../reducer";
import { connect } from "react-redux";
import { createUser } from "../../reducer/userReducer";

export interface IReactProps {}

export interface IReduxProps {
  createUser: (email: string, password: string, name: string) => void;
}

export interface IState {
  isFormValid: boolean;
  formData: { [key: string]: string };
}

class Registration extends React.Component<IReactProps & IReduxProps, IState> {
  public state: IState = {
    formData: {
      password: "",
      confirmPassword: "",
      emailAddress: "",
      name: ""
    },
    isFormValid: false
  };

  public render() {
    return (
      <div className={styles.registration}>
        <div className={styles.div}>
          <img className={styles.img} src={logo} />
          <h1 className={styles.h1}>MY NORTHSTAR</h1>
          <h2 className={styles.h2}>Explore your purpose, register below</h2>
          <form>
            <fieldset className={styles.form}>
              <label className={styles.label}>Name:</label>
              <TextInput
                inputName="name"
                inputPlaceholder=""
                inputType="name"
                inputValue={this.state.formData.name}
                inputOnChange={this.handleFormChange}
              />

              <label className={styles.label}>Email:</label>
              <TextInput
                inputName="emailAddress"
                inputPlaceholder=""
                inputType="email"
                inputValue={this.state.formData.email}
                inputOnChange={this.handleFormChange}
              />

              <label className={styles.label}>Password:</label>
              <TextInput
                inputName="password"
                inputPlaceholder=""
                inputType="password"
                inputValue={this.state.formData.password}
                inputOnChange={this.handleFormChange}
              />

              <label>Confirm Password:</label>
              <TextInput
                inputName="confirmPassword"
                inputPlaceholder=""
                inputType="password"
                inputValue={this.state.formData.confirmPassword}
                inputOnChange={this.handleFormChange}
              />
            </fieldset>
            <div className={styles.statement}>
              <div className={styles.statementText} ref="nameInput">
                {this.isfullNamePresent() ? "" : "- Full name required"}
              </div>
              <div className={styles.statementText} ref="passwordInput">
                {this.doPasswordsMatch() ? "" : "- Passwords do not match"}
              </div>
              <div className={styles.statementText} ref="passwordDif">
                {this.isPasswordLengthCorrect()
                  ? ""
                  : "- Password must have at least 6 characters"}
              </div>
              <div className={styles.statementText} ref="emailInput">
                {this.isEmailValid() ? "" : "- Valid email required"}
              </div>
            </div>
            <div className={styles.div}>{this.renderSubmitButton()}</div>
          </form>
          <NavLink to="./login">
            <p>Return to the login</p>
          </NavLink>
        </div>
      </div>
    );
  }

  public logValues = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (this.state.isFormValid) {
      this.setState({
        isFormValid: false
      });
      this.props.createUser(
        this.state.formData.emailAddress,
        this.state.formData.password,
        this.state.formData.name
      );
    }
  };

  private renderSubmitButton = () => {
    if (this.state.isFormValid) {
      return (
        <SignUpButton
          buttonText="Register"
          confirmLoginDetails={this.logValues}
        />
      );
    } else {
      return (
        <SignUpButtonDefunct
          buttonText="Register"
          confirmLoginDetails={this.logValues}
        />
      );
    }
  };

  private doPasswordsMatch = () => {
    return this.state.formData.password === this.state.formData.confirmPassword;
  };

  private isPasswordLengthCorrect = () => {
    return this.state.formData.password.length >= 6;
  };

  private isEmailValid = () => {
    const emailState = this.state.formData.emailAddress;
    return (
      emailState.indexOf(".") !== -1 &&
      emailState.indexOf("@") !== -1 &&
      emailState.length > 0
    );
  };
  private isfullNamePresent = () => {
    const userNameState = this.state.formData.name;
    return userNameState.split(" ").length >= 0 && userNameState.length > 1;
  };

  private checkFormValid = () => {
    let isFormValid: boolean = false;
    if (
      this.isEmailValid() &&
      this.isfullNamePresent() &&
      this.isPasswordLengthCorrect() &&
      this.doPasswordsMatch()
    ) {
      isFormValid = true;
    }
    this.setState({ isFormValid });
  };

  private handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    this.setState(
      {
        formData: { ...this.state.formData, [name]: event.target.value }
      },
      this.checkFormValid
    );
  };
}

const mapStateToProps = (state: IStore, props: IReactProps) => {
  return {};
};

const mapDispatchToProps = { createUser };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Registration);
