import { combineReducers } from "redux";
import generalReducer, { IGeneralState } from "./generalReducer";
import registrationReducer, { IRegistrationState } from "./registrationReducer";
import userReducer, { IUserState } from "./userReducer";

export interface IStore {
  general: IGeneralState;
  registration: IRegistrationState;
  user: IUserState;
}

export default combineReducers({
  general: generalReducer,
  registration: registrationReducer,
  user: userReducer
});
