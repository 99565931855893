import * as React from "react";
import styles from "./questionnaire.module.scss";

export interface IProps {
  editValue: (value: string, category: string, id: number) => void;
  text: string;
  category: string;
  id: number;
}

export interface IState {
  value: number;
  counter: string;
}

class Question extends React.Component<IProps, IState> {
  public state = { value: 5, counter: "" };

  public handleEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      counter: event.target.value,
      value: parseInt(event.target.value)
    });
    this.props.editValue(
      event.target.value,
      this.props.category,
      this.props.id
    );
  };

  public render() {
    return (
      <div className={styles.questionnaire}>
        <p className={styles.text}>{this.props.text}</p>

        <div className={styles.slideContainer}>
          <div className={styles.scoreIndicator}>
            <p className={styles.paratext}>{this.state.counter}</p>
          </div>
          <input
            type="range"
            min="0"
            max="10"
            value={this.state.value}
            className={styles.slider}
            onChange={this.handleEvent}
          />
        </div>
      </div>
    );
  }
}

export default Question;
