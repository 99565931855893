import * as React from "react";
import Registration from "../components/registration/registration-page";

export interface IProps {}

export interface IState {}

class RegistrationContainer extends React.Component<IProps, IState> {
  public render() {
    return <Registration />;
  }
}

export default RegistrationContainer;
