import * as React from "react";
import PageIntro from "../components/pageIntro/page-intro";
import { setPageName, IStarDataSets } from "../utilities";
import Star from "../components/star/star";
import { firestore, auth } from "../firebase";
import styles from "./star-page-container.module.scss";
import { IStarData } from "../utilities";
import { connect } from "react-redux";
import { IStore } from "../reducer";
import history from "../history";
import Galaxy from '../modules/galaxy';
import '../Content/index.less';

export interface IReactProps {}
export interface IReduxProps {
  ourUser: any;
}
export interface IState {
  ourData: IStarData;
  pointSize: number;
  isMounted: boolean;
}

class StarPageContainer extends React.Component<
    IReactProps & IReduxProps,
    IState
    > {
  public categoryLabelsFromDB: string[] = [];
  public categoryScoresFromDB: number[] = [];
  public starData!: IStarDataSets;
  public dataRetrievedFromDB!: firebase.firestore.DocumentData[];
  public scoresWithFakeDataPoints: number[] = [];
  public starLabel: string = "";
  public clearList: Function[] = [];
  public updateList: Function[] = [];

  // divider and sharpness control "aggressiveness" and "curve" of the star.

  public divider: number = 1.3;
  public sharpness: number = 0.3;
  public galaxy: Object = {};

  public state = {
    isMounted: false,
    pointSize: 2,

    // "empty" initial state for the data object - C.S.

    ourData: {
      labels: [],
      datasets: [
        {
          label: "Star 1",
          data: [],
          backgroundColor: "rgba(101, 68, 155, 0.4)",
          borderColor: "rgba(110, 203, 211, 0.8)",
          pointRadius: [1],
          lineTension: 0.4,
          borderWidth: 1
        }
      ]
    }
  };

  public componentDidMount = () => {
    setPageName("My Star");

    // this.clear(this.sidebar.endSubscribe(throttle(() => this.galaxy.resize(), 250)));
    // this.clear(() => galaxy.destroy());
    // setTimeout(() => galaxy.resize(), 1000);
    //=========================================================================================
    firestore
      .collection("questionnaires")
      .where("user.uid", "==", this.props.ourUser.uid)
      .get()
      .then(querySnapshot => {
        this.dataRetrievedFromDB = querySnapshot.docs.map(document =>
          document.data()
        );

        // The user has never done a questionnaire... so redirect them.

        if (!this.dataRetrievedFromDB[0]) {
          alert(
            "You have no star to display as you have never completed a questionnaire. You are being redirected!"
          );
          history.push("/in-app/questionnaire");
        }

        // Mapping data from database to our variables - C.S.
        // Inserting empty string between each label for purpose of fake data points - C.S.

        if (this.dataRetrievedFromDB[0]) {
          this.dataRetrievedFromDB.sort((element1, element2) => {
            if (element1.date.seconds < element2.date.seconds) {
              return 1;
            } else return -1;
          });

          this.dataRetrievedFromDB[0].categoryResults.map((element: any) => {
            this.categoryLabelsFromDB.push(element.categoryName);
            this.categoryLabelsFromDB.push("");
            this.categoryScoresFromDB.push(element.categoryAverage);
          });

          // Date of last questionnaire

          this.starLabel = this.dataRetrievedFromDB[0].date
            .toDate()
            .toString()
            .slice(0, 25);

          // Data to be passed to star component. Can change colours etc here - C.S.

          this.starData = {
            label: "Star 1",
            data: this.buildFakeDataArray(this.categoryScoresFromDB),
            backgroundColor: "rgba(101, 68, 155, 0.6)",
            borderColor: "rgba(110, 203, 211, 0.8)",
            pointRadius: this.buildPointSizeArray(),
            lineTension: this.sharpness,
            borderWidth: 1
          };

          // Setting state ready to pass data object to star component - C.S.

          this.setState({
            isMounted: true,
            ourData: {
              ...this.state.ourData,
              labels: this.categoryLabelsFromDB,
              datasets: [this.starData]
            }
          });

              //==============================================================
    let stData = [];

    stData = this.dataRetrievedFromDB[0].categoryResults.map(item => {
      return {
        name: item.categoryName,
        value: parseFloat(item.categoryAverage.toFixed(1)),
        colSize: ['overview__label-col', 'overview__value-col'],
        max: 10,
        label: item.categoryName,
        axis: item.categoryName.replace(/ /g, '_'),
        state: true
      };
    });
    // };

    const el = "#galaxy";

    if (el === null || el === undefined) return;

    let galaxy: any;
    galaxy = new (Galaxy as any)(el);

    galaxy.init(stData, { starPadding: 5});
        } else {
          console.log("NO DATA FOR STAR");
        }
      });
  };

  public render() {
    return (
        <React.Fragment>
          <div className={styles.wrapper}>
            <svg className="svg-sprite" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <filter id="star-point-glow" width="800%" height="800%" x="-350%" y="-350%" filterRes="1000">
                    <feGaussianBlur stdDeviation="2" />

                    <feComponentTransfer result="offsetmorph">
                        <feFuncA type="table" tableValues="0 .05 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1" />
                    </feComponentTransfer>

                    <feGaussianBlur stdDeviation="17" result="glow"></feGaussianBlur>

                    <feMerge>
                        <feMergeNode in="glow"></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                    </feMerge>
                </filter>

                <filter id="star-guide-glow" width="200%" height="200%" x="-50%" y="-50%" filterRes="1000">
                  <feGaussianBlur stdDeviation="4" />

                  <feComponentTransfer result="offsetmorph">
                      <feFuncA type="table" tableValues="0 .05 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 1" />
                  </feComponentTransfer>

                    <feGaussianBlur id="gauss" stdDeviation="50" result="glow"></feGaussianBlur>
                    <feMerge>
                      <feMergeNode in="glow"></feMergeNode>
                      <feMergeNode in="glow"></feMergeNode>
                      <feMergeNode in="glow"></feMergeNode>
                    </feMerge>
                </filter>
              </defs>
            </svg>
            <div className="core-kpis" style={{ display: 'flex', width: '70%' }}>
              <div className="core-kpis__col core-kpis__col--star" style={{ display: 'flex', width: '100%' }}>
                <div className="core-kpis__star js-galaxy-center-guide js-galaxy" id="galaxy" style={{ display: 'flex', width: '100%', margin: '10px' }}>
                  
                </div>
              </div>
            </div>
            <div className={styles.pageText}>
              <PageIntro heading="WELCOME TO YOUR PERSONAL STAR">
                The power of the star is in its visual representation of how you
                scored each area of your life. The key is not to focus on the
                elements with lower score but to think what would I like to
                improve. Always be thinking where am I now and where would I like
                to be in a month year etc. Let you aims goals and desires drive
                the tasks you set for yourself.
                <br />
                <br />
                Last star completed on:
                <br />
                {this.starLabel}
              </PageIntro>
            </div>
          </div>
        </React.Fragment>
    );
  }

  public handleEvent = (event: any, val: number) => {
    switch (val) {
      case 1:
        this.sharpness = event.target.value / 10;

        this.starData = {
          ...this.starData,
          lineTension: this.sharpness
        };

        // Setting state ready to pass data object to star component - C.S.

        this.setState({
          ourData: {
            ...this.state.ourData,
            labels: this.categoryLabelsFromDB,
            datasets: [this.starData]
          }
        });
        break;
      case 2:
        this.divider = event.target.value / 10 + 1;

        this.starData = {
          ...this.starData,
          data: this.buildFakeDataArray(this.categoryScoresFromDB)
        };

        // Setting state ready to pass data object to star component - C.S.

        this.setState({
          ourData: {
            ...this.state.ourData,
            labels: this.categoryLabelsFromDB,
            datasets: [this.starData]
          }
        });
        break;

      default:
        break;
    }
  };

  // Inserting fake data points into our dataset

  public buildFakeDataArray = (arr: number[]): number[] => {
    const returnedArray = [];
    for (let i = 0; i < arr.length; i++) {
      returnedArray.push(arr[i]);
      if (i === arr.length - 1) {
        if (arr[i] > arr[0]) {
          returnedArray.push(arr[0] / this.divider);
        } else {
          returnedArray.push(arr[i] / this.divider);
        }
      } else if (arr[i] > arr[i + 1]) {
        returnedArray.push(arr[i + 1] / this.divider);
      } else {
        returnedArray.push(arr[i] / this.divider);
      }
    }
    return returnedArray;
  };

  // Set zero pointsize for our fake data points so they don't appear on the star

  public buildPointSizeArray = () => {
    const returnedArray = [];
    for (let i = 0; i < 9; i++) {
      returnedArray.push(this.state.pointSize);
      returnedArray.push(0);
    }
    return returnedArray;
  };

  clear(func: Function) {
    if (typeof func === 'function') {
      this.clearList.push(func);
    }
  }

  update(func: Function) {
    if (typeof func === 'function') {
      this.updateList.push(func);
    }
  }

  dispose() {
    this.clearList.forEach(func => func());
    this.clearList = [];
  }

  callUpdateList() {
    this.updateList.forEach(func => func());
  }
}

const mapStateToProps = (state: IStore, props: IReactProps) => {
  return {
    ourUser: state.user.user
  };
};

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StarPageContainer);
