import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";

// const firebaseConfig = {
//   apiKey: "AIzaSyAowqJnCpZiAQbBhYNpEfN_juXgcuJ-9hg",
//   authDomain: "my-northstar-dev.firebaseapp.com",
//   databaseURL: "https://my-northstar-dev.firebaseio.com",
//   projectId: "my-northstar-dev",
//   storageBucket: "my-northstar-dev.appspot.com",
//   messagingSenderId: "1061711582934"
// };

const firebaseConfig = {
    apiKey: "AIzaSyCVCABsn3G0zPqvbm9tfj9O9tH78rP5ObM",
    authDomain: "my-northstar.firebaseapp.com",
    databaseURL: "https://my-northstar.firebaseio.com",
    projectId: "my-northstar",
    storageBucket: "my-northstar.appspot.com",
    messagingSenderId: "540559413927",
    appId: "1:540559413927:web:e8933aa735afaa92524182"
  };


firebase.initializeApp(firebaseConfig);
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();

export default firebase;