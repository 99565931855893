export const SET_PAGE_NAME = "SET_PAGE_NAME";

export interface ISetPageName {
  type: typeof SET_PAGE_NAME;
  pageName: string;
}

export interface IGeneralState {
  pageName: string;
}

export const setPageName = (pageName: string): ISetPageName => {
  document.title = "Northstar | " + pageName;
  return {
    type: SET_PAGE_NAME,
    pageName
  };
};

export const initialState: IGeneralState = {
  pageName: "MyNorthstar"
};

const generalReducer = (state = initialState, action: ISetPageName) => {
  switch (action.type) {
    case SET_PAGE_NAME:
      return { ...state, pageName: action.pageName };
    default:
      return state;
  }
};

export default generalReducer;
