import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import Forgot from "./components/forgot/forgot";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faBars,
  faStar,
  faHistory,
  faQuestionCircle,
  faSignOutAlt,
  faCog,
  faListOl,
  faUserCircle,
  faSortDown
} from "@fortawesome/free-solid-svg-icons";
import { BrowserRouter } from "react-router-dom";
library.add(
  fab,
  faBars,
  faStar,
  faHistory,
  faQuestionCircle,
  faSignOutAlt,
  faCog,
  faListOl,
  faUserCircle,
  faSortDown
);

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// Prior to 12th April 2019 we had been amending the ReactDOM.render function
// to test our individual components. After routing is complete, we shouldn't
// need to amend this file. -TS

// import Forgot from  "./components/forgot/forgot";
// import Login from   "./components/login-page/login";

// ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
