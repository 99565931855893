import * as React from "react";
import Forgot from "../components/forgot/forgot";

export interface IProps {}

export interface IState {}

class ForgotContainer extends React.Component<IProps, IState> {
  public render() {
    return <Forgot />;
  }
}

export default ForgotContainer;
