import Store from "./store";
import { setPageName as setPageNameActionCreator } from "./reducer/generalReducer";

export const setPageName = (pageName: string) => {
  Store.dispatch(setPageNameActionCreator(pageName));
};

export interface IStarData {
  labels: string[];
  datasets: IStarDataSets[];
}

export interface IStarDataSets {
  label: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
  pointRadius: number[];
  lineTension: number;
  borderWidth: number;
}
