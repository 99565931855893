/* eslint-disable */
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import moment from 'moment';

export const dateFormat = 'DD/MM/YYYY';
export const isoFormat = 'YYYY-MM-DD';

const dateRegex = new RegExp(/^(\d{2,4})([.\/-])\d{2}\2(\d{2,4})$/); // eslint-disable-line no-useless-escape
export function sortList({list = [], sortFrom = 0, optionsText = '', order = 'asc'}) {
	if (list.constructor !== Array) return list;

	const checkType = (v) => {
		const val = typeof v === 'function' ? v() : v;

		if (typeof val === 'string') {
			let string = val.toLowerCase();

			// test if the string is date
			if (dateRegex.test(string)) {
				const dateFormat = string.replace(dateRegex, (s, g1, divider, g3) => {
					return [
						`${g1.length === 4 ? 'YYYY' : 'DD' }`,
						`${divider}`,
						`MM`,
						`${divider}`,
						`${g3.length === 4 ? 'YYYY' : 'DD' }`
					].join('');
				});

				return moment(string, dateFormat).toDate();
			} else {
				return isNaN(+string) ? string : +string;
			}
		} else if (val === null || val === undefined) {
			return order === 'asc' ? Infinity : -Infinity;
		}

		return val;
	}

	return [...list.slice(0, sortFrom), ...orderBy(list.slice(sortFrom, list.length), (d) => {
		return optionsText
			? checkType(get(d, optionsText)) 
			: checkType(d)
	}, order)];
}

export const flattenData = (data, childKey) => {
	return data.reduce((acc, val) => {
		if (val[childKey]) {
			if (typeof val[childKey] === 'function' && val[childKey]().length) {
				acc.push(...flattenData(val[childKey](), childKey));
			} else if (val[childKey].length) {
				acc.push(...flattenData(val[childKey], childKey));
			}
		}

		acc.push({...val, [childKey]: undefined});

		return acc;
	}, []);
}

export const clearSearchString = (str = '') => str.replace(/\s+/g, (val, index) => {
	return index === 0 
		? ''
		: ' ';
}).replace(/[^\w\s.'&]/gi, '');

export const parseFilterItem = text => (text && typeof text === 'string') ? text.trim().replace(/\s+/g, ' ') : '';
export const trimCamelCase = (string = '') => string.replace(/([^\sA-Z])([A-Z]{1,})/g, '$1 $2').trim();

export function getLocaleNumber(number = 0) {
	if (typeof number !== 'number' && typeof number !== 'string' && number !== '') return number;

	return number.toString().replace(/,/g, '').replace(/\d+(\.\d{1,})?/g, n => (n.length > 3) ? parseFloat(parseFloat(n).toFixed(2)).toLocaleString() : n );
}

export const toFixed = (number = 0) => parseFloat(!number ? number : number.toString().replace(/\.(\d{1})(\d+)$/, '.$1'));

export function getTransformation(transform) {
	if (!transform) {transform = 'translate(0, 0) scale(1)';}
	// Create a dummy g for calculation purposes only. This will never
	// be appended to the DOM and will be discarded once this function 
	// returns.
	var g = document.createElementNS('http://www.w3.org/2000/svg', 'g');

	// Set the transform attribute to the provided string value.
	g.setAttributeNS(null, 'transform', transform);

	// consolidate the SVGTransformList containing all transformations
	// to a single SVGTransform of type SVG_TRANSFORM_MATRIX and get
	// its SVGMatrix. 
	var matrix = g.transform.baseVal.consolidate().matrix;

	// Below calculations are taken and adapted from the private function
	// transform/decompose.js of D3's module d3-interpolate.
	var { a, b, c, d, e, f } = matrix; // ES6, if this doesn't work, use below assignment
	// var a=matrix.a, b=matrix.b, c=matrix.c, d=matrix.d, e=matrix.e, f=matrix.f; // ES5
	var scaleX = Math.sqrt(a * a + b * b), 
		scaleY = Math.sqrt(c * c + d * d), 
		skewX = a * c + b * d;
	if (scaleX) a /= scaleX, b /= scaleX;
	if (skewX) c -= a * skewX, d -= b * skewX;
	if (scaleY) c /= scaleY, d /= scaleY, skewX /= scaleY;
	if (a * d < b * c) a = -a, b = -b, skewX = -skewX, scaleX = -scaleX;
	return {
		translateX: e,
		translateY: f,
		rotate: Math.atan2(b, a) * Math.PI / 180,
		skewX: Math.atan(skewX) * Math.PI / 180,
		scaleX: scaleX,
		scaleY: scaleY
	};
}

export class PreloaderUtils {
	constructor(obs) {
		this.count = 0;
		this.obs = obs;
	}

	isLoading(val) {
		if (val) {
			this.count++;
			this.obs(true);
		} else {
			this.count--;
			if (this.count <= 0) this.obs(false);
		}
	}
}

export const downloadCSV = (data, filename = 'export') => {
	let csv = data;

	if (!csv) return;

	if (!csv.match(/^data:text\/csv/i)) {
        csv = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
	}
    const link = document.createElement('a');
	link.setAttribute('href', csv);
	link.setAttribute('download', filename + '.csv');
	link.click();
	link.remove();
}

export const getNormalizeValue = (val) => typeof val === 'function' ? val() : val;

export function detectIE() {
  var ua = window.navigator.userAgent;
  if (/MSIE|Trident\/|Edge\//.test(ua)) {
	return true;
  }
  
  // other browser
  return false;
}


export const scrollToBottom = (el, scrollDistance, delay = 1000, scrollUp = false) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			/*
				* scrollDistance / ~60px/s + 2s buffer
				* * 1000 -> s to ms
			 */
			const timeout = (scrollDistance / 60 + 2) * 1000; 
			let start = null;

			function step(timestamp) {
				if (!start) start = timestamp;
				let progress = timestamp - start;

                el.scrollTop += scrollUp ? -1 : 1;

				if (el.scrollTop === scrollDistance || progress > timeout) {
					resolve();
				} else {
					window.requestAnimationFrame(step);
				}
			}
			window.requestAnimationFrame(step);
		}, delay);
	});
}