import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducer";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { verifyAuth } from "../reducer/userReducer";
import { initialState as generalState } from "../reducer/generalReducer";
import { registrationState } from "../reducer/registrationReducer";

const initialState = {
  general: generalState,
  registration: registrationState
};

const middlewares = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares))
);

store.dispatch<any>(verifyAuth());

export default store;
