import * as React from "react";
import styles from "./profile.module.scss";
import PrimaryButton from "../primaryButton/primaryButton";
import TextInput from "../textBox/textInput/textInput";
import { setPageName } from "../../utilities";
import { storage } from "../../firebase";
import mountain from "../../assets/backgrounds/mountain.jpg";
import { connect } from "react-redux";
import { IStore } from "../../reducer";
import { IUser, updatePicture } from "../../reducer/userReducer";
import { userInfo } from "os";
import { auth } from "../../firebase";
import { updateName, updateEmail } from "../../reducer/userReducer";

export interface IReactProps {}

export interface IReduxProps {
  updateName: (newName: string) => void;
  updateEmail: (newEmail: string) => void;
  displayName?: string;
  email?: string;
  photoURL?: string;
  updatePicture: (newPic: string) => void;
  user: IUser;
}

export interface IState {
  email: string;
  name: string;
  file: any;
}

class ProfilePage extends React.Component<IReactProps & IReduxProps, IState> {
  public state: IState = {
    email: "",
    name: "",
    file: ""
  };

  public componentDidMount() {
    setPageName("Profile");
  }

  public render() {
    return (
      <section>
        <div className={styles.topSection}>
          <img
            className={styles.profilePhoto}
            src={this.props.photoURL ? this.props.photoURL : mountain}
          />
          <div className={styles.details}>
            <h2 className={styles.profileName}>{this.props.displayName}</h2>
            <h4>{this.props.email}</h4>
          </div>
        </div>
        <div className={styles.mainSection}>
          <h2>Update Profile</h2>
          <form className={styles.profileForm} onSubmit={this.updateEmail}>
            <label>Change Email Address:</label>
            <TextInput
              inputName="changeemail"
              inputType="email"
              inputPlaceholder=""
              inputValue={this.state.email}
              inputOnChange={this.updateEmailInputState}
            />
            <PrimaryButton buttonText="Update Email" />
          </form>
          <form className={styles.profileForm} onSubmit={this.updateName}>
            <label>Change Name:</label>
            <TextInput
              inputName="changename"
              inputType="text"
              inputPlaceholder=""
              inputValue={this.state.name}
              inputOnChange={this.updateNameInputState}
            />
            <PrimaryButton buttonText="Update Name" />
          </form>
          <div className={styles.updatePictureFrame}>
            <label className={styles.pictureLabel}>
              Change Profile Picture:{" "}
            </label>
            <img
              className={styles.smallProfilePhoto}
              src={this.props.photoURL ? this.props.photoURL : mountain}
            />
            <label className={styles.fileInputFrame}>
              Update Picture
              <input
                className={styles.fileInput}
                type="file"
                name="file"
                accept="image/*"
                onChange={event => this.handleImageChange(event)}
              />
            </label>
          </div>
        </div>
      </section>
    );
  }

  private handleImageChange(event: any) {
    event.preventDefault();
    const file = event.target.files[0];
    this.setState({
      file
    });
    storage
      .ref()
      .child(this.props.user.uid + "")
      .put(file)
      .then(snapshot =>
        snapshot.ref
          .getDownloadURL()
          .then(downloadURL => this.props.updatePicture(downloadURL))
          .catch(error => console.log("error"))
      )
      .catch(error => console.log("error"));
  }

  private updateEmailInputState = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      email: event.target.value
    });
  };

  private updateNameInputState = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      name: event.target.value
    });
  };

  public updateName = (event: React.FormEvent<HTMLFormElement>) => {
    // Stops default page refresh
    event.preventDefault();
    this.props.updateName(this.state.name);
    // wipes form
    this.setState({
      name: ""
    });
  };

  private updateEmail = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.props.updateEmail(this.state.email);
    this.setState({
      email: ""
    });
  };
}

const mapStateToProps = (state: IStore, props: IReactProps) => {
  return {
    email: state.user.user.email,
    displayName: state.user.user.displayName,
    photoURL: state.user.user.photoURL,
    user: state.user.user
  };
};

const mapDispatchToProps = { updateName, updateEmail, updatePicture };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePage);
