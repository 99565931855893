import * as React from "react";
import styles from "./sign-up-submit-button.module.scss";
export interface IProps {
  buttonText: string;
  confirmLoginDetails: any;
}

export interface IState {}

class GreyButton extends React.Component<IProps, IState> {
  public render() {
    return (
      <button
        className={styles.button2}
        onClick={this.props.confirmLoginDetails}
      >
        {this.props.buttonText}
      </button>
    );
  }
}

export default GreyButton;
