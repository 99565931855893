import Star from './star';
import difference from 'lodash/difference';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import Colors from './spare/colorScale';
import Tooltip from './spare/tooltip';
import qActions from './quick-actions';
import sliceQa from './spare/slice-qa/slice-qa';
import { select } from 'd3-selection';
import { getTransformation } from './utils';
import 'd3-selection-multi';
import * as $ from "jquery";

// const $ = window.jQuery;
var Galaxy = function Galaxy(wrapper) {

	var colors = new Colors(),	
		count = 0,
		stars = {},
		exists = [],
		activeStar = '',
		lastAction = '',
		starTipClass = '.js-g-trigger',

		updateDuration = 500,
		transitionDuration = 1000,
		cGuide = $('.js-galaxy-center-guide');


	// create svg and groups
	var svg = select(wrapper)
		.append('svg')
		.attrs({
			'height': $(wrapper).height(),
			'width': $(wrapper).width()
		});

	function setSvgSize() {
		var height = $(wrapper).height(),
			width = $(wrapper).width();

		select(wrapper).select('svg')
			.attrs({
				'height': height,
				'width': width
			});

		return !!height && !!width;
	}
	var defs = svg.append('defs');
	var galaxy = svg.append('g')
		.attr('transform', 'translate(0, 0) scale(1)');
	galaxy.append('g')
		.attr('class', 'stars-guide');


	/*=================================================
	=            Draw star shared elements            =
	=================================================*/

	/*
	Draw label question mark
	 */
	var helpWrapper = defs.append('g')
		.attr('id', 'help');
	helpWrapper.append('circle')
		.attrs({
			class: 'star-label__circle',
			r: 8
		});
	helpWrapper.append('text')
		.attrs({
			class: 'star-label__qa',
			y: 5
		}).text('?');


	/*
	Draw radial gradient
	 */
	defs.append('g').attr('class', 'gradients').append('radialGradient')
		.attr('id', 'star-gradient')
		.call(function(el) {
			for (var i = 0; i < colors.list.length; i++) {
				el.append('stop')
					.attr('offset', i * 50 + '%')
					.attr('stop-color', colors.list[i]);
			}
		});


	/*
	Draw star mask
	 */
	Star.prototype.mask = defs.append('mask')
		.attr('id', 'star-mask')
		.append('circle')
		.attrs({
			cx: 0,
			cy: 0,
			fill: '#fff'
		});

	// defs axis wrapper
	defs.append('g').attr('class', 'star-axes');
	/*=====  End of Draw star shared elements  ======*/



	/*=======================================
	=            Star prototypes            =
	=======================================*/


	Star.prototype.getColor = colors;
	Star.prototype.transitionDuration = transitionDuration;
	Star.prototype.updateDuration = updateDuration;
	Star.prototype.cGuide = cGuide;
	Star.prototype.starTooltip = {
		init: function(data, rect) {
			$(starTipClass).css({
				top: rect.top + 'px',
				left: rect.left + 'px',
				width: rect.width + 'px',
				height: rect.height + 'px',
				display: 'block'
			});

			starTip.update(data);
			$(starTipClass).tooltipster('show');
		},
		hide: function() {
			$(starTipClass).hide();
			$(starTipClass).tooltipster('hide');
		}
	};
	Star.prototype.qa = qActions;
	Star.prototype.sliceQa = sliceQa;
	Star.prototype.defs = defs;

	/*=====  End of Star prototypes  ======*/



	/*=========================================================
	=            Append galaxy tip trigger element            =
	=========================================================*/

	$('.js-gallaxy').append('<div class="galaxy-tip-trigger js-g-trigger">');
	var starTip = new Tooltip($('.js-g-trigger')[0], {
		title: 'title',
		content: 'test content'
	});

	/*=====  End of Append galaxy tip trigger element  ======*/



	function createStar(data, name, parentName, tooltipContent) {
		var hName = name;
		name = name.toLowerCase() + count++;
		
		var starData = data.reduce((acc, val) => {
			if (val.data) {
				acc.push({...val, isChild: true, data: []})
			} else {
				acc.push(val);
			}

			return acc;
		}, []);
	
		if (stars[name]) {
			stars[name].update(starData);
		} else {
			var state = 'star';

			if (stars[parentName]) {
				state = stars[parentName].state.get();
			}
			
			stars[name] = new Star(svg, galaxy, starData, {
				name: name,
				parentName: parentName,
				state: state,
				humanName: hName,
				tooltipContent: tooltipContent

			}, count, focus);
		}

		exists.push(name);

		data.forEach(function(d) {

			if (d.data && d.data.length) {
				var star = createStar(d.data, d.axis, name, d.tooltip);

				d.starName = star;
			}
		});

		return name;
	}

	var proceedDuration = 0;
	var focus = {
		star: function(name, passGalaxy, passCheck) {
			var _this = this;

			if (!name || !stars[name]) {
				return;
			}
            
			var starData = stars[name].getFocusData();
			var newPos = _this.getNewPosition(starData);

			if (!passCheck) {
				if (activeStar === name) {
					return;
				}
			}

			// let gData = stars[name].getGalaxyData();
			// newPos.gData = this.getNewPosition(gData);

			if (!passGalaxy) {
				_this.galaxy(name, function() {
					_this.toggleStates(name, []);

					starData = stars[name].getFocusData();
					newPos = _this.getNewPosition(starData);

					_this.proceed(newPos, name);
				});
			} else {
				_this.toggleStates(name, []);
				_this.proceed(newPos, name);
			}

			activeStar = name;
			lastAction = 'star';
		},
		galaxy: function(name, done, passCheck) {
			if (stars[name] && (!name || !stars[name])) {
				return;
			}

			name = name.replace('-galaxy', '');
			var starData = stars[name].getGalaxyData();
			var newPos = this.getNewPosition(starData);

			if (!passCheck) {
				if (activeStar === name + '-galaxy') {
					if (done && typeof done === 'function') {
						done();
					}
					return;
				}
			}

			activeStar = name + '-galaxy';
			lastAction = 'galaxy';

			this.toggleStates(name, starData.list, newPos.scale);
			this.proceed(newPos, name, done);
		},
		proceed: function({translate, scale}, name, done) {
			galaxy
				.transition()
				.duration(proceedDuration)
				.attr('transform', `translate(${translate[0]}, ${translate[1]}) scale(${scale})`)
				.on('end', function() {
					if (done && typeof done === 'function') {
						done();
					}	
				});
		},
		getNewPosition: function(data) {
			var t = getTransformation(galaxy.attr('transform')),
				scaleVal = data.size / data.rect.width,
				scale = t.scaleX * scaleVal;

			var cGuideRect = cGuide[0].getBoundingClientRect();

			var width = parseInt(cGuide.width()),
				posLeft = cGuideRect.left,
				posTop = cGuideRect.top,
				height = parseInt(svg.style('height')),
				cx = width / 2 + posLeft,
				cy = height / 2 + posTop,
				px = (data.rect.left * scaleVal) + ((data.rect.width / 2) * scaleVal),
				py = (data.rect.top * scaleVal) + ((data.rect.width / 2) * scaleVal),

				dx = cx - px + (t.translateX * scaleVal),
				dy = cy - py + (t.translateY * scaleVal);

			return {translate: [dx, dy], scale};
		},

		toggleStates: function(active, list, scaleVal) {
			var all = Object.keys(stars);

			all.splice(all.indexOf(active), 1);

			stars[active].show(true);
			stars[active].preview.off();

			if (list.length) {
				list.forEach(function(el) {
					var i = all.indexOf(el);
					if (i !== -1) {
						all.splice(i, 1);
						stars[el].show();
						stars[el].preview.on(scaleVal);
					}
				});
			}

			if (all.length) {
				all.forEach(function(el) {
					stars[el].hide();
					stars[el].preview.off();
				});
			}
		},

        center: function (name, duration = 0) {
            if (name === '' || name === undefined) return;
			var starData = stars[name].getFocusData(),
				newPos = this.getNewPosition(starData);

			lastAction = 'star';
			activeStar = name;

			galaxy
				.transition()
				.duration(duration)
				.attr('transform', `translate(${newPos.translate[0]}, ${newPos.translate[1]}) scale(${newPos.scale})`);
			
			activeStar = name;
		}
	};

	// var resizeTimer;
	// function resizer() {
	// 	var self = this;
	// 	if (!isEmpty(stars)) {
	// 		clearTimeout(resizeTimer);
	// 		resizeTimer = setTimeout(function() {
	// 			self.center();
	// 		}, updateDuration);

	// 		qActions.hide();
	// 		if (setSvgSize()) {
	// 			forEach(stars, function(item) {
	// 				item.resize();
	// 			});
	// 		}
	// 	}
	// }


	const recenter = debounce(function() {
		this.center();
	}, updateDuration);
	function resizer() {
		if (!isEmpty(stars)) {
			// clearTimeout(resizeTimer);
			// this.center();
			recenter.call(this);

			qActions.hide();
			if (setSvgSize()) {
				forEach(stars, function(item) {
					item.resize();
				});
			}
		}
	}

	return {
		init: function(data, options) {
			if (!data.length) {return;}

			var self = this;

			if (typeof options === 'undefined') {
				options = {};
			}

			if (options.key) {
				Star.prototype.keyName = options.key;
			}

			if (typeof options.starPadding !== 'undefined') {
				Star.prototype.starPadding = options.starPadding;
			}

			Star.prototype.onStarClick = options.onStarClick;
			Star.prototype.onSliceClick = options.onSliceClick;
			Star.prototype.onSliceMouseover = options.onSliceMouseover;
			Star.prototype.onSliceMouseleave = options.onSliceMouseleave;

			createStar(data, 'core');

			focus.star('core0', true);
			proceedDuration = Star.prototype.updateDuration;

			$(window).on('resize.galaxy', debounce(() => resizer.call(self), 100));
			// $(window).on('resize.galaxy', resizer.bind(self));

			return data;
		},

		center: function() {
			focus[lastAction](activeStar, true, true);
		},

		update: function(data, keyName, duration) {
			$('.js-g-trigger').hide();

			if (keyName) {
				Star.prototype.keyName = keyName;
			}

			// set custom update duration if provided
			if (duration) {
				Star.prototype.updateDuration = duration;

				setTimeout(function() {
					Star.prototype.updateDuration = updateDuration;
				}, duration);
			}


			qActions.hide();

			count = 0;
			exists = [];
			createStar(data, 'core');

			// destroy missing stars
			var diff = difference(Object.keys(stars), exists);

			if (diff.length) {
				diff.forEach(function(d) {
					stars[d].destroy();
					delete stars[d];
				});
			}

			if (lastAction === 'star') {
				focus.center(activeStar, Star.prototype.updateDuration);
			}
		},

		resize: function() {
			setSvgSize();
			forEach(stars, function(item) {
				item.resize();
			});
			focus.center(activeStar, 250);
		},

		focus: focus,

		destroy: function() {
			Object.keys(stars).forEach(function(item) {
				stars[item].destroy();
				delete stars[item];
			});
			$(window).off('resize.galaxy');
			svg.remove();
			cGuide.remove();
			defs.remove();
			galaxy.remove();
			helpWrapper.remove();
		}
	};
};

export default Galaxy;
