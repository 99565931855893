import * as React from "react";
import Password from "../components/create-password/password";

export interface IProps {}

export interface IState {}

class CreatePasswordContainer extends React.Component<IProps, IState> {
  public render() {
    return <Password />;
  }
}

export default CreatePasswordContainer;
