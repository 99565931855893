import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { NavLink } from "react-router-dom";
import styles from "./nav.module.scss";

export interface IProps {
  navShown: boolean;
  menuIsClicked: () => void;
}

export interface IState {}

class Nav extends React.Component<IProps, IState> {
  public render() {
    return (
      <nav
        className={`${this.props.navShown ? "navIsVisible" : ""} ${styles.nav}`}
      >
        <ul className={styles.navFlex}>
          <div className={styles.navLinks}>
            {" "}
            <NavLink to="/in-app/my-star">
              <div
                className={styles.navLinkContainer}
                onClick={this.props.menuIsClicked}
              >
                <FontAwesomeIcon className={styles.icon} icon="star" />
                <li className={styles.navText}>My Star</li>
              </div>
            </NavLink>
          </div>
          <div className={styles.navLinks}>
            {" "}
            <NavLink to="/in-app/compare-star">
              <div
                className={styles.navLinkContainer}
                onClick={this.props.menuIsClicked}
              >
                <FontAwesomeIcon className={styles.icon} icon="history" />
                <li className={styles.navText}>Compare Star</li>
              </div>
            </NavLink>
          </div>
          <div className={styles.navLinks}>
            <NavLink to="/in-app/questionnaire">
              <div
                className={styles.navLinkContainer}
                onClick={this.props.menuIsClicked}
              >
                <FontAwesomeIcon className={styles.icon} icon="list-ol" />
                <li className={styles.navText}>New Questionnaire</li>
              </div>
            </NavLink>
          </div>
        </ul>
      </nav>
    );
  }
}

export default Nav;
